/* eslint-disable */
<script>

  import { formatUnitOfAccount, 
    toUnitOfAccount, 
    getInterpolatedColor,
    getColorValuationRatio,
    getColor10Ratio,
    formatPercent,
    getColorDividendYield,
    getColorDividendYieldSupportedByFreeCashflow,
  } from '../helper';
  import { logUserEvent } from '../helper';

  import { sessionManager } from '@/main';

 
  export default {
    name: 'CompanyValuationSimpleMetrics',
    props: {
      valuation: {
        type: Object,
        required: true,
        default: () => null,
      },
      unitOfAccount: {
        type: String,
        required: true,
        default: () => 'USD',
      },
      marketCap: {
        type: Object,
        required: true,
        default: () => null,
      },
      logo: {
        type: Boolean,
        default: () => false
      },
      logoText: {
        type: String,
        default: () => ''
      },
    },
    data() {
      return {
        languageStrings: {
          en: {
            title: 'Simple Metrics',
            combined_valuation: 'Combined Valuation (Price / Valuation)',
            price_earnings: 'Price / Earnings (P/E)',
            price_fcf: 'Price / Free Cashflow (P/FCF)',
            price_oe: 'Price / Owner Earnings (P/OE)',
            dividend_yield: 'Dividend Yield',
            dividend_supported: 'Dividend in % of Free Cashflow',
            price_book_value: 'Price / Book Value (P/B)',
           
          },
          de: {
            title: 'Einfache Messgrössen',
            combined_valuation: 'Kombinierte Bewertung (Preis / Bewertung)',
            price_earnings: 'Kurs-Gewinn-Verhältnis (KGV)',
            price_fcf: 'Preis zu freiem Cashflow (P/FCF)',
            price_oe: 'Preis zu Owner Earnings (P/OE)',
            dividend_yield: 'Dividendenrendite',
            dividend_supported: 'Dividenden in % des freien Cashflow',
            price_book_value: 'Preis zu Buchwert (P/B)',
          }
        },
        language: ''
      };
    },
    created() {
      this.language = sessionManager.read('language');
      logUserEvent('companyValuation', sessionManager, this.$vuetify.breakpoint.mobile);
    },
    methods: {
      formatRatio(value, price)
      {
        if(price == 0)
        {
          return '-';
        }
        return (value/price).toFixed(1);
      }, 
    },
    computed: {
      strings() {
          return this.languageStrings[this.language];
      },
      vrFormatted()
      {
        if(this.valuation.combinedValuationRatio == 1000)
        {
          return '-';
        }
        return this.valuation.combinedValuationRatio.toFixed(1);
      },
      vrFormattedColor()
      {
        return getColorValuationRatio(this.vrFormatted);
      },
      peFormatted()
      {
        var price = toUnitOfAccount(this.marketCap, this.unitOfAccount);
        return this.formatRatio(price, this.valuation.tenCapEarningsValuation[0].netIncome);
      },
      peFormattedColor()
      {
        return getColor10Ratio(this.peFormatted);
      },
      pfcFormatted()
      {
        var price = toUnitOfAccount(this.marketCap, this.unitOfAccount);
        return this.formatRatio(price, this.valuation.tenCapFreeCashflowValuation[0].freeCashFlow);
      },
      pfcFormattedColor()
      {
        return getColor10Ratio(this.pfcFormatted);
      },
      poeFormatted()
      {
        var price = toUnitOfAccount(this.marketCap, this.unitOfAccount);
        return this.formatRatio(price, this.valuation.tenCapOwnerEarningsValuation[0].ownerEarnings);
      },
      poeFormattedColor()
      {
        return getColor10Ratio(this.poeFormatted);
      },
      dividendYieldFormatted()
      {
        return formatPercent(this.valuation.dividendValuation[0].dividendYield);
      },
      dividendYieldFormattedColor()
      {
        return getColorDividendYield(this.valuation.dividendValuation[0].dividendYield);
      },
      dividendYieldSupportedByFreeCashflowFormatted()
      {
        if(this.valuation.dividendValuation[0].dividendsAsPartOfFreeCashflow < 0)
        {
          return '-';
        }
        else
        {
          return formatPercent(this.valuation.dividendValuation[0].dividendsAsPartOfFreeCashflow);
        }
      },
      dividendYieldSupportedByFreeCashflowFormattedColor()
      {
        return getColorDividendYieldSupportedByFreeCashflow(this.valuation.dividendValuation[0].dividendsAsPartOfFreeCashflow);
      },
      pbFormatted()
      {
        console.log(this.valuation.bookValueValuation[0].priceToBookValue);
        var price = toUnitOfAccount(this.marketCap, this.unitOfAccount);
        return this.formatRatio(price, this.valuation.bookValueValuation[0].bookValue);
      },
      pbFormattedColor()
      {
        return getColor10Ratio(this.pbFormatted*10);
      }
    }
  }
</script>
  
  
<template>
  <div class="px-v-md sm:px-v-xl py-v-md sm:py-v-xl">
    <div class="flex">
      <div class="flex-auto">
        <h3 v-if="logo" class="darkest font-pj text-v-xl text-bold">{{ logoText }}</h3>
        <h3 class="darkest font-pj text-v-xl text-bold" style="text-align:left;float:left;">{{strings['title']}}</h3>
      </div>
      <div class="flex-none">
        <p style="text-align:right;">
        </p>
      </div>
    </div>


    <v-simple-table v-if="valuation" dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="darkest font-pj text-v-base pl-0"></th>
            <th class="darkest font-pj text-v-base pl-0" align="right"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="!logo">
            <td class="darkest font-pj text-v-base pl-0">{{strings['combined_valuation']}}</td>
            <td class="darkest font-pj text-v-base pl-0" align="right" :style="{ 'background-color': vrFormattedColor}">{{ vrFormatted }}</td>
          </tr>    
          <tr>
            <td class="darkest font-pj text-v-base pl-0">{{strings['price_earnings']}}</td>
            <td class="darkest font-pj text-v-base pl-0" align="right" :style="{ 'background-color': peFormattedColor}">{{ peFormatted }}</td>
          </tr>
          <tr>
            <td class="darkest font-pj text-v-base pl-0">{{strings['price_fcf']}}</td>
            <td class="darkest font-pj text-v-base pl-0" align="right" :style="{ 'background-color': pfcFormattedColor}">{{ pfcFormatted }}</td>
          </tr>
          <tr>
            <td class="darkest font-pj text-v-base pl-0">{{strings['price_oe']}}</td>
            <td class="darkest font-pj text-v-base pl-0" align="right" :style="{ 'background-color': poeFormattedColor}">{{ poeFormatted }}</td>
          </tr> 
          <tr>
            <td class="darkest font-pj text-v-base pl-0">{{strings['price_book_value']}}</td>
            <td class="darkest font-pj text-v-base pl-0" align="right" :style="{ 'background-color': pbFormattedColor}">{{ pbFormatted }}</td>
          </tr>
          <tr>
            <td class="darkest font-pj text-v-base pl-0">{{strings['dividend_yield']}}</td>
            <td class="darkest font-pj text-v-base pl-0" align="right" :style="{ 'background-color': dividendYieldFormattedColor}">{{ dividendYieldFormatted }}</td>
          </tr>    
          <tr>
            <td class="darkest font-pj text-v-base pl-0">{{strings['dividend_supported']}}</td>
            <td class="darkest font-pj text-v-base pl-0" align="right" :style="{ 'background-color': dividendYieldSupportedByFreeCashflowFormattedColor}">{{ dividendYieldSupportedByFreeCashflowFormatted }}</td>
          </tr>          
        </tbody>
      </template>
    </v-simple-table>
    <div v-if="logo">
      <div class="flex justify-end">
        <div class="flex-none text-v-base font-pj py-v-md"><svg width="15x" height="15px" viewBox="0 0 7.9375 7.9375" version="1.1" id="svg5" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg"><defs id="defs2"><rect x="30" y="486" width="372.5" height="75.259857" id="rect1465-7-8" /></defs><g id="layer1" transform="translate(195.79166,-445.55834)"><circle style="fill:#71b224;fill-opacity:1;stroke-width:0.296467;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:6.8" id="path1525-7" cx="-191.82291" cy="449.52707" r="3.96875" /><g aria-label="v" transform="matrix(0.30397883,0,0,0.30397907,-203.69511,295.47429)" id="text1463-6-5" style="font-weight:bold;font-size:32px;line-height:1.25;font-family:'Plus Jakarta Sans';-inkscape-font-specification:'Plus Jakarta Sans Bold';letter-spacing:0px;word-spacing:0px;white-space:pre;shape-inside:url(#rect1465-7-8);display:inline;fill:#ffffff"><path d="m 37.2,516.3619 -6.848,-17.408 h 4.576 l 4.96,13.696 h -1.696 l 4.992,-13.696 h 4.576 l -6.848,17.408 z" id="path1322" /></g></g></svg>
        </div>
        <div class="flex-none text-v-base font-pj py-v-md pl-v-sm pr-v-md"><b>value</b>tion</div>
      </div>
    </div>
  </div>
</template>
/* eslint-enable */ 
  