
<script>
import { sessionManager } from '@/main';
import { eventBus } from '@/main'

export default {
  name: 'Admin',
  props: {
  },
  data() {
    return {
      languageStrings: {
        en: {
          title: 'Admin',
        },
        de: {
          title: 'Admin',
        },
      },
      waitlistEMail: '',
      valid: true,
    };
  },
  created() {
    this.language = sessionManager.read('language');

    if(!sessionManager.isAdmin())
    {
      this.$router.push('/login');
    }
  },
  beforeMount() {
    
  },
  computed: {
    strings() {
        return this.languageStrings[this.language];
    },
  },
  methods: {
    async submitForm()
    {
      const url = '/api/HttpTriggerSendInvitation?';
      const response = await fetch(url +
        new URLSearchParams({
          email: this.waitlistEMail,
        }), { 
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type' : 'application/json',
          'JWT' : sessionManager.read('token'),
        },
      });

      // check if response is ok
      if(response.ok) {
        eventBus.$emit('success', 'invitation sent to ' + this.waitlistEMail);
        this.waitlistEMail = '';
      }
      else
      {
        // show error message
        eventBus.$emit('error', 'failed to send invitation email: ' + this.waitlistEMail + ' Error: ' + response.status + ' ' + response.statusText);
      }
    },   
    async sendQuestionairs()
    {
      const url = '/api/HttpTriggerSendQuestionaire?';
      const response = await fetch(url, { 
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type' : 'application/json',
          'JWT' : sessionManager.read('token'),
        },
      });

      // check if response is ok
      if(response.ok) {
        eventBus.$emit('success', 'questionairs sent');
      }
      else
      {
        // show error message
        eventBus.$emit('error', 'failed to send questionairs email: Error: ' + response.status + ' ' + response.statusText);
      }
    },    
    async testRSSInput()
    {
      const url = '/api/HttpTriggerTestRSSInput?';
      const response = await fetch(url, { 
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type' : 'application/json',
          'JWT' : sessionManager.read('token'),
        },
      });
    },    
    async testRSSInputReset()
    {
      const url = '/api/HttpTriggerTestRSSInputReset?';
      const response = await fetch(url, { 
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type' : 'application/json',
          'JWT' : sessionManager.read('token'),
        },
      });
    },   
    async httpTrigger(urlName)
    {
      const url = '/api/' + urlName + '?';
      const response = await fetch(url, { 
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type' : 'application/json',
          'JWT' : sessionManager.read('token'),
        },
      });
    },
  },
}
</script>

<style scoped>


</style>

<template>
  <div>
    <div class="bg-primary flex justify-center">
      <h2 :class="$vuetify.breakpoint.smAndDown ? 'text-lightest text-v-xl font-pj font-bold py-v-md' : 'text-lightest text-v-xl font-pj font-bold py-v-md'">{{strings['title']}}</h2>
    </div>

    <div :class="$vuetify.breakpoint.smAndDown ? 'px-v-2xl' : 'px-v-4xl'">
      <div class="pt-v-xl">
        <h3 :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-xl font-pj font-bold py-v-md' : 'darkest text-v-xl font-pj font-bold py-v-md'">FMP Data</h3>
        <button type="submit" class="flex-none bg-primary border rounded-md border-solid border-primary regular text-lightest font-pj transition-all duration-200 py-v-sm px-v-md hover:bg-primary-light hover:border-primary-light" 
          @click="httpTrigger('HttpTriggerTestFMPData')">FMP Data</button>
        <button type="submit" class="flex-none bg-primary border rounded-md border-solid border-primary regular text-lightest font-pj transition-all duration-200 py-v-sm px-v-md hover:bg-primary-light hover:border-primary-light" 
          @click="httpTrigger('HttpTriggerTestFMPDataReset')">FMP Data Reset</button>
        <button type="submit" class="flex-none bg-primary border rounded-md border-solid border-primary regular text-lightest font-pj transition-all duration-200 py-v-sm px-v-md hover:bg-primary-light hover:border-primary-light" 
          @click="httpTrigger('HttpTriggerTestFMPDataDownload')">FMP Data Download</button>
        <button type="submit" class="flex-none bg-primary border rounded-md border-solid border-primary regular text-lightest font-pj transition-all duration-200 py-v-sm px-v-md hover:bg-primary-light hover:border-primary-light" 
          @click="httpTrigger('HttpTriggerTestFMPDataDownloadReset')">FMP Data Download Reset</button>
      </div>  
      <div class="pt-v-xl">
        <h3 :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-xl font-pj font-bold py-v-md' : 'darkest text-v-xl font-pj font-bold py-v-md'">FH Data</h3>
        <button type="submit" class="flex-none bg-primary border rounded-md border-solid border-primary regular text-lightest font-pj transition-all duration-200 py-v-sm px-v-md hover:bg-primary-light hover:border-primary-light" 
          @click="httpTrigger('HttpTriggerTestFHData')">FH Data</button>
        <button type="submit" class="flex-none bg-primary border rounded-md border-solid border-primary regular text-lightest font-pj transition-all duration-200 py-v-sm px-v-md hover:bg-primary-light hover:border-primary-light" 
          @click="httpTrigger('HttpTriggerTestFHDataReset')">FH Data Reset</button>
       
      </div>  

      <div class="pt-v-xl">
        <h3 :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-xl font-pj font-bold py-v-md' : 'darkest text-v-xl font-pj font-bold py-v-md'">FRED Data</h3>
        <button type="submit" class="flex-none bg-primary border rounded-md border-solid border-primary regular text-lightest font-pj transition-all duration-200 py-v-sm px-v-md hover:bg-primary-light hover:border-primary-light" 
          @click="httpTrigger('HttpTriggerTestFREDDataDownload')">FRED Data Download</button>
       
       
      </div>  

      <div class="pt-v-xl">
        <h3 :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-xl font-pj font-bold py-v-md' : 'darkest text-v-xl font-pj font-bold py-v-md'">Send EMails</h3>
        <button type="submit" class="flex-none bg-primary border rounded-md border-solid border-primary regular text-lightest font-pj transition-all duration-200 py-v-sm px-v-md hover:bg-primary-light hover:border-primary-light" 
          @click="httpTrigger('HttpTriggerTestSendNewTermsUpdates')">Update Terms & Conditions</button>
      </div>  

      <div class="pt-v-xl">
        <h3 :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-xl font-pj font-bold py-v-md' : 'darkest text-v-xl font-pj font-bold py-v-md'">RSS Input</h3>
        <button type="submit" class="flex-none bg-primary border rounded-md border-solid border-primary regular text-lightest font-pj transition-all duration-200 py-v-sm px-v-md hover:bg-primary-light hover:border-primary-light" 
          @click="testRSSInput">RSS Input</button>
          <button type="submit" class="flex-none bg-primary border rounded-md border-solid border-primary regular text-lightest font-pj transition-all duration-200 py-v-sm px-v-md hover:bg-primary-light hover:border-primary-light" 
          @click="testRSSInputReset">RSS Input Reset</button>
      </div>

      <div class="pt-v-xl">
        <h3 :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-xl font-pj font-bold py-v-md' : 'darkest text-v-xl font-pj font-bold py-v-md'">Tweets</h3>
        <button type="submit" class="flex-none bg-primary border rounded-md border-solid border-primary regular text-lightest font-pj transition-all duration-200 py-v-sm px-v-md hover:bg-primary-light hover:border-primary-light" 
          @click="httpTrigger('HttpTriggerTestTweet')">Tweet</button>
      </div>

      
    </div>

    <!--
    <div :class="$vuetify.breakpoint.smAndDown ? 'px-v-2xl' : 'px-v-4xl'">
      <div class="pt-v-xl">
        <h3 :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-xl font-pj font-bold py-v-md' : 'darkest text-v-xl font-pj font-bold py-v-md'">Send Waitlist Invitation</h3>

        <v-form ref="form" v-model="valid" @submit.prevent="submitForm">
          <div class="text-v-sm darkest font-pj pb-v-sm">{{strings['email']}}</div>
          <div>
            <v-text-field
              v-model="waitlistEMail"
              outlined
            />
          </div>
          <button type="submit" :disabled="!valid" class="flex-none bg-primary border rounded-md border-solid border-primary regular text-lightest font-pj transition-all duration-200 py-v-sm px-v-md hover:bg-primary-light hover:border-primary-light">Send Invitation</button>
        </v-form>

      </div>
      <div class="pt-v-xl">
        <h3 :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-xl font-pj font-bold py-v-md' : 'darkest text-v-xl font-pj font-bold py-v-md'">Send Survey</h3>
        <button type="submit" :disabled="!valid" class="flex-none bg-primary border rounded-md border-solid border-primary regular text-lightest font-pj transition-all duration-200 py-v-sm px-v-md hover:bg-primary-light hover:border-primary-light" @click="sendQuestionairs">Send Questionairs</button>
      </div>  
    </div>
    -->
  </div>
</template>


