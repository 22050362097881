/* eslint-disable */
import { Colors, adjustColor, interpolateColors } from './colors';


/*---------------------------------------------------------------------------------------------------------/
// get the SEC Filings
/---------------------------------------------------------------------------------------------------------*/
export function getCompanySECFilings(inputSymbol, sessionManager)
{
  const url = '/api/HttpTriggerGetCompanySECFilings?';
  return fetch(
    url +
      new URLSearchParams({
        symbol: inputSymbol,
      }),
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type' : 'application/json',
        'JWT' : sessionManager.read('token'),
      },
    }
  ).then((response) => response.json());
}

/*---------------------------------------------------------------------------------------------------------/
// getSymbolList
/---------------------------------------------------------------------------------------------------------*/
export function getSymbolList(sessionManager) {
  const url = '/api/HttpTriggerGetSymbolList';
  return fetch(url,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type' : 'application/json',
        'JWT' : sessionManager.read('token'),
      },
    }
  ).then((response) => response.json());
}

/*---------------------------------------------------------------------------------------------------------/
// addApostroph
/---------------------------------------------------------------------------------------------------------*/
export function addApostroph(nStr) {
  nStr += '';
  var x = nStr.split('.');
  var x1 = x[0];
  var x2 = x.length > 1 ? '.' + x[1] : '';
  var rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
          x1 = x1.replace(rgx, '$1' + "'" + '$2');
  }
  return x1 + x2;
}

/*---------------------------------------------------------------------------------------------------------/
// formatMillions
/---------------------------------------------------------------------------------------------------------*/
export function formatMillions(number, showFractions = false) {
  number /= 1000000;
  number = Math.round(number * 100) / 100;
  if(!showFractions || (number > 1000 || number < -1000))
  {
    number = number.toFixed(0);
  }
  else
  {
    number = number.toFixed(2);
  }
  const str = addApostroph(number.toString())
  return str + ' Mio';
}

/*---------------------------------------------------------------------------------------------------------/
// formatMillions
/---------------------------------------------------------------------------------------------------------*/
export function formatBillions(number, showFractions = false) {
  number /= 1000000000;
  number = Math.round(number * 100) / 100;
  if(!showFractions || (number > 1000 || number < -1000))
  {
    number = number.toFixed(0);
  }
  else
  {
    number = number.toFixed(2);
  }
  const str = addApostroph(number.toString())
  return str + ' B';
}

/*---------------------------------------------------------------------------------------------------------/
// formatMillions
/---------------------------------------------------------------------------------------------------------*/
export function formatMillionsChart(number) {
  number /= 1000000;
  number = Math.round(number);
  const str = addApostroph(number.toString())
  return str + ' Mio';
}

/*---------------------------------------------------------------------------------------------------------/
// formatBillions
/---------------------------------------------------------------------------------------------------------*/
export function formatBillionsChart(number, language) {
  number /= 1000000000;
  number = Math.round(number);
  const str = addApostroph(number.toString())
  if(language === 'de')
  {
    return str + ' Mrd';
  }
  else
  {
    return str + ' B';
  }
}


/*---------------------------------------------------------------------------------------------------------/
// formats into %
/---------------------------------------------------------------------------------------------------------*/
export function formatPercentOfM2(number) {
  number *= 100;

  if(Math.abs(number) >= 0.0001 || number == 0)
  {
    number = number.toFixed(5);
  }
  else
  {
    number = number.toExponential(3);
  }
  
  return number.toString() + ' % of M2 $';
}

/*---------------------------------------------------------------------------------------------------------/
// formats onto 2 digits
/---------------------------------------------------------------------------------------------------------*/
export function formatSharePrice(number) {
  if(number > 1)
  {
    number = Math.round(number * 100) / 100;
  }
  else if(number != 0)
  {
    let log = Math.log10(number);
    let round = 100;
    for(let i = 0; i < -log; i++)
    {
      round *= 10;
    }
    number = Math.round(number * round) / round;
  }
    
  return number.toString();
}

/*---------------------------------------------------------------------------------------------------------/
// formatUnitOfAccount for the tweets, where we round quite a bit
/---------------------------------------------------------------------------------------------------------*/
export function formatUnitOfAccountTweets(number, unitOfAccount) {
  let str = '';
  let unit = '';
  let showFractions = false;
  switch(unitOfAccount) {
    case 'USD':
    case 'EUR':
      // decide if we show millions or billions
      if(number > 1000000000 || number < -1000000000)
      {
        // we show billions
        number /= 1000000000;

        number = Math.round(number * 10) / 10;
        if(number > 10 || number < -10)
        {
          number = number.toFixed(0);
        }
        else
        {
          number = number.toFixed(1);
        }
        str = addApostroph(number.toString())
        str += 'B';
      }
      else
      {
        // we show millions
        number /= 1000000;
        number = Math.round(number * 100) / 100;
        number = number.toFixed(0);
        str = addApostroph(number.toString())
        return str + 'Mio';
      }
      break;
    case 'EUR':
      str = formatMillions(number, showFractions);
      break;
    case 'BTC':
      str = formatBTC(number, showFractions);  
      break;
    case 'GC':
      str = formatGold(number, showFractions);
      break;
    case 'PercentOfM2USD':
      str = formatPercentOfM2(number);
      break;
    default:
  }

  switch(unitOfAccount) {
    case 'USD':
      unit = '$';
      break;
    case 'EUR':
      unit = '€';
      break;
    case 'BTC':
      unit = '₿';    
      break;
    case 'GC':
      unit = 'ounces';
      break;
    case 'PercentOfM2USD':
      unit = '';
      break;
    default:
  }

  str += ' ' + unit;
  return str;
}

/*---------------------------------------------------------------------------------------------------------/
// formatUnitOfAccount
/---------------------------------------------------------------------------------------------------------*/
export function formatUnitOfAccount(number, unitOfAccount, showUnit = false, showFractions = false) {
  let str = '';
  let unit = '';
  switch(unitOfAccount) {
    case 'USD':
      str = formatMillions(number, showFractions);
      unit = '$';
      break;
    case 'EUR':
      str = formatMillions(number, showFractions);
      unit = '€';
      break;
    case 'JPY':
      str = formatBillions(number, showFractions);
      unit = '¥';
      break;
    case 'GBP':
      str = formatMillions(number, showFractions);
      unit = '£';
      break;
    case 'CHF':
      str = formatMillions(number, showFractions);
      unit = 'CHF';
      break;
    case 'BTC':
      str = formatBTC(number, showFractions);
      unit = '₿';    
      break;
    case 'GC':
      str = formatGold(number, showFractions);
      unit = 'Gold (oz)';
      break;
    case 'PercentOfM2USD':
      str = formatPercentOfM2(number);
      unit = '';
      break;
    default:
  }

  if(showUnit)
  {
    str += ' ' + unit;
  }
  return str;
}

/*---------------------------------------------------------------------------------------------------------/
// formatUnitOfAccount
/---------------------------------------------------------------------------------------------------------*/
export function getUnitOfAccountUnit(unitOfAccount) {
  let unit = '';
  switch(unitOfAccount) {
    case 'USD':
      unit = '$';
      break;
    case 'EUR':
      unit = '€';
      break;
    case 'BTC':
      unit = '₿';    
      break;
    case 'GC':
      unit = 'Gold (oz)';
      break;
    case 'PercentOfM2USD':
      unit = '%';
      break;
    default:
  }

  return unit;
}

/*---------------------------------------------------------------------------------------------------------/
// formatUnitOfAccount for Charts
/---------------------------------------------------------------------------------------------------------*/
export function formatUnitOfAccountChart(number, unitOfAccount, language) {
  return formatUnitOfAccount(number, unitOfAccount, true, false)

  switch(unitOfAccount) {
    case 'USD':
      if(false) // number >= 1000000000 || number <= -1000000000)
      {
        return formatBillionsChart(number, language);
      }
      return formatMillionsChart(number);
    case 'EUR':
      return formatMillionsChart(number);
    case 'BTC':
      return formatBTC(number);
    case 'GC':
      return formatGold(number);
    case 'PercentOfM2USD':
      return formatPercentOfM2(number);
    default:
      return number;
  }
}

/*---------------------------------------------------------------------------------------------------------/
// formatBTC
/---------------------------------------------------------------------------------------------------------*/
export function formatBTC(number) {
  /*
  if(number > 21000000) {
    return 'max BTC';
  }
  if(number < -21000000) {
    return '-max BTC';
  }
  */
  //console.log('formatBTC: input ' + number);

  if(number > 1000 || number < -1000)
  {
    number = number.toFixed(0);
  }
  else if(number > 100 || number < -100)
  {
    number = number.toFixed(1);
  }
  else 
  {
    number = number.toFixed(2);
  }

  //console.log('formatBTC: rounded ' + number);

  //console.log('formatBTC: addAprostoph ' + addApostroph(number.toString()));
  return addApostroph(number.toString());
}

/*---------------------------------------------------------------------------------------------------------/
// formatGold
/---------------------------------------------------------------------------------------------------------*/
export function formatGold(number, showFractions = false) {
  //number /= 1000; // convert to kilo ounces;
  number = Math.round(number * 100) / 100;
  if(!showFractions || (number > 1000 || number < -1000))
  {
    number = number.toFixed(0);
  }
  else
  {
    number = number.toFixed(2);
  }
  const str = addApostroph(number.toString())
  return str;// + ' K';

  return formatMillions(numberOfOunces);
  //var number = ((Math.round(ratio * 100) / 100)).toFixed(1);
  //return addApostroph(number.toString());
}

/*---------------------------------------------------------------------------------------------------------/
// formatRatio
/---------------------------------------------------------------------------------------------------------*/
export function formatRatio(ratio) {
  return Math.round(ratio * 100) / 100;
}

/*---------------------------------------------------------------------------------------------------------/
// formatPercent
/---------------------------------------------------------------------------------------------------------*/
export function formatPercent(ratio, toFixed = 1) {
  return (100 * (Math.round(ratio * 1000) / 1000)).toFixed(toFixed) + ' %';
}


/*---------------------------------------------------------------------------------------------------------/
// converts/reads the right price in the unitOfAccount
/---------------------------------------------------------------------------------------------------------*/
export function toUnitOfAccount(currencyGroup, unitOfAccount)
{
  switch (unitOfAccount)
  {
    case 'USD':
      return currencyGroup.priceInUSD;
    case 'EUR':
      return currencyGroup.priceInEuro;
    case 'JPY':
      return currencyGroup.priceInJPY;
    case 'GBP':
      return currencyGroup.priceInGBP;
    case 'CHF':
      return currencyGroup.priceInCHF;
    case 'GC':
      return currencyGroup.priceInGold;
    case 'BTC':
      return currencyGroup.priceInBitcoin;
    case 'PercentOfM2USD':
      return currencyGroup.priceInPercentOfM2USD;
    default:
      return currencyGroup.price;
  }
}


/*---------------------------------------------------------------------------------------------------------/
// computes a color between red-yellow-green for a set of good/bad values. assumption, bad is smaller than good 
/---------------------------------------------------------------------------------------------------------*/
export function getInterpolatedColor(value, veryBad, bad, sightlyBad, neutral, slightlyGood, good, veryGood, colorNeutral = Colors.White, pastel = true) {

  let amount = 80;
  var colorVeryBad, colorBad, colorSlightlyBad, colorSlightlyGood, colorGood, colorVeryGood;

  if(pastel)
  {
    colorVeryBad = Colors.PastelLightRed;
    colorBad = Colors.PastelLightOrange;
    colorSlightlyBad = Colors.PastelLightYellow;
    colorSlightlyGood = Colors.White;
    colorGood = Colors.PastelLightGreen;
    colorVeryGood = Colors.PastelGreen;
  }
  else
  {
    colorVeryBad = Colors.GradientRed;
    colorBad = adjustColor(Colors.GradientRed, amount);
    colorSlightlyBad = adjustColor(Colors.GradientOrange, amount);
    colorSlightlyGood = adjustColor(Colors.GradientLightGreen, amount);
    colorGood = adjustColor(Colors.GradientGreen, amount);
    colorVeryGood = Colors.GradientGreen;
  }

  if(value < veryBad)
  {
    return colorVeryBad;
  }
  if(value < bad)
  {
    let diff = 1 - Math.abs(value - bad) / Math.abs(veryBad-bad);
    return interpolateColors(colorVeryBad, colorBad, diff);
  }
  if(value < sightlyBad)
  {
    let diff = 1 - Math.abs(value - sightlyBad) / Math.abs(bad-sightlyBad);
    return interpolateColors(colorBad, colorSlightlyBad, diff);
  }
  if(value < neutral)
  {
    let diff = 1 - Math.abs(value - neutral) / Math.abs(sightlyBad-neutral);
    return interpolateColors(colorSlightlyBad, colorNeutral, diff);
  }
  if(value < slightlyGood)
  {
    let diff = 1 - Math.abs(value - slightlyGood) / Math.abs(neutral-slightlyGood);
    return interpolateColors(colorNeutral, colorSlightlyGood, diff);
  }  
  if(value < good)
  {
    let diff = 1 - Math.abs(value - good) / Math.abs(slightlyGood-good);
    return interpolateColors(colorSlightlyGood, colorGood, diff);
  }
  if(value < veryGood)
  {
    let diff = 1 - Math.abs(value - veryGood) / Math.abs(good-veryGood);
    return interpolateColors(colorGood, colorVeryGood, diff);
  }
  
  return colorVeryGood;
}

export function replaceUnderscoreWithDot(str) {
  return str.replace(/_/g, ".");
}

/*---------------------------------------------------------------------------------------------------------/
// computes a color between for valuation ratios
/---------------------------------------------------------------------------------------------------------*/
export function getColorValuationRatio(value) 
{
  var ratio = 1.0/value;

  if(value == '-')
  {
    ratio = 0;
  }

  let veryBad = 0.25;
  let bad = 0.4;
  let slightlyBad = 0.6;
  let neutral = 0.75;
  let slightlyGood = 1;
  let good = 1.25;
  let veryGood = 2;

  let colorNeutral = Colors.White;
  return getInterpolatedColor(ratio, veryBad, bad, slightlyBad, neutral, slightlyGood, good, veryGood, colorNeutral);
}

/*---------------------------------------------------------------------------------------------------------/
// computes a color for PE, FreeCashflow ratios where 10 is good
/---------------------------------------------------------------------------------------------------------*/
export function getColor10Ratio(value) 
{
  return getColorValuationRatio(value/10);
}

/*---------------------------------------------------------------------------------------------------------/
// computes a color for the dividend yield
/---------------------------------------------------------------------------------------------------------*/
export function getColorDividendYield(dividendYield)
{
  var ratio = dividendYield;
  let veryBad = -0.2;
  let bad = -0.1;
  let slightlyBad = -0.05;
  let neutral = 0;
  let slightlyGood = 0.05;
  let good = 0.1;
  let veryGood = 0.2;

  let colorNeutral = Colors.White;
  return getInterpolatedColor(ratio, veryBad, bad, slightlyBad, neutral, slightlyGood, good, veryGood, colorNeutral);
}


/*---------------------------------------------------------------------------------------------------------/
// computes a color for the percentage of the dividend yield in relation of free cashflow
/---------------------------------------------------------------------------------------------------------*/
export function getColorDividendYieldSupportedByFreeCashflow(dividendsAsPartOfFreeCashflow)
{
  var ratio = 1-dividendsAsPartOfFreeCashflow;
  if(dividendsAsPartOfFreeCashflow < 0)
  {
    ratio = -1;
  }
  let veryBad = -1;
  let bad = -0.5;
  let slightlyBad = -0.15;
  let neutral = 0;
  let slightlyGood = 0.10;
  let good = 0.25;
  let veryGood = 1;

  let colorNeutral = Colors.White;
  return getInterpolatedColor(ratio, veryBad, bad, slightlyBad, neutral, slightlyGood, good, veryGood, colorNeutral);
}

/*---------------------------------------------------------------------------------------------------------/
// registerUser
/---------------------------------------------------------------------------------------------------------*/
export function registerUser(email, password) {
  const url = '/api/HttpTriggerRegisterUser?';
  return fetch(
    url +
      new URLSearchParams({
        email: email,
        password: password,
      }),
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    }
  ).then((response) => response.json());
}

export function logArticleClick(articleId, sessionManager) {

  console.log('logArticleClick: ' + articleId + ' sessionManager: ' + sessionManager);

  if(window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1')
  {
    // we are on localhost -> do not log
    return;
  }

  const url = '/api/HttpTriggerLogArticleEvent?';

  return fetch(
    url +
    new URLSearchParams({
      event: 'click',
      articleId: articleId,
      userId: sessionManager.read('id'),
    }),
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    }
  );
}

/*---------------------------------------------------------------------------------------------------------/
// Logging of the user event
/---------------------------------------------------------------------------------------------------------*/
export function logUserEvent(event, sessionManager, mobile, metainfo = null) 
{

  //console.log('logUserEvent: ' + event + ' id: ' + sessionManager.read('id') + ' mobile: ' + mobile + ' metainfo: ' + metainfo);

  if(window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1')
  {
    // we are on localhost -> do not log
    return;
  }

  const url = '/api/HttpTriggerLogUserEvent?';

  return fetch(
    url +
    new URLSearchParams({
      event: event,
      id: sessionManager.read('id'),
      email: sessionManager.read('email'),
      mobile: mobile,
      metainfo: metainfo,
    }),
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    }
  ); 
}

/*---------------------------------------------------------------------------------------------------------/
// Format datestring to year and quarter
/---------------------------------------------------------------------------------------------------------*/
export function getYearAndQuarter(dateString, halfYear = false) {
  // Parse the date
  const date = new Date(dateString);

  // Get the year
  const year = date.getFullYear();

  // Get the month and determine the quarter
  const month = date.getMonth();
  let quarter;
  let half;
  if (month < 3) {
      quarter = 'Q1';
      half = 'H1';
  } else if (month < 6) {
      quarter = 'Q2';
      half = 'H1';
  } else if (month < 9) {
      quarter = 'Q3';
      half = 'H2';
  } else {
      quarter = 'Q4';
      half = 'H2';
  }

  if(halfYear)
  {
    return `${year} ${half}`;
  }

  // Return the result
  return `${year} ${quarter}`;
}

/*---------------------------------------------------------------------------------------------------------/
// Format datestring to year
/---------------------------------------------------------------------------------------------------------*/
export function getYear(dateString) {
  // Parse the date
  const date = new Date(dateString);

  // Get the year
  const year = date.getFullYear();

  return `${year}`;
}

/*---------------------------------------------------------------------------------------------------------/
// Format datestring to year
/---------------------------------------------------------------------------------------------------------*/
export function formatGermanDate(dateString) 
{
  // Parse the date string into a Date object
  var date = new Date(dateString);

  // Define an array of German month names
  var months = [
      'Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 
      'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'
  ];

  // Get the day, month, and year from the Date object
  var day = date.getUTCDate();
  var month = date.getUTCMonth();
  var year = date.getUTCFullYear();

  // Format the date string as "day month year"
  return day + ' ' + months[month] + ' ' + year;
}