
<script>
import { sessionManager } from '@/main';
import CompanySearch from './company-search.vue';
import CompanyGrowth from './company-growth.vue';
import CompanyManagement from './company-management.vue';
import OverviewGraphCashflow from './overview-graph-cashflow.vue';
import OverviewGraphBalance from './overview-graph-balance.vue';
import OverviewGraphIncome from './overview-graph-income.vue';
import CompanyValuationTable from './company-valuation-table.vue';
import CompanyValuationPaybackTimeFreeCashflow from './company-valuation-payback-time-free-cashflow.vue';
import CompanyFinancialsIncomeGraph from './company-financials-income-graph.vue';
import CompanyFinancialsBalanceGraph from './company-financials-balance-graph.vue';
import CompanyFinancialsCashflowGraph from './company-financials-cashflow-graph.vue';
import CompanyValuationHistorical from './company-valuation-historical.vue';


import {
  logUserEvent,
  replaceUnderscoreWithDot,
} from '../helper';

export default {
  name: 'Onboarding',
  props: {
    trackSteps: {
      type: Boolean,
      required: true,
      default: () => false,
    },
  },
  components: {
    CompanySearch,
    CompanyGrowth,
    CompanyManagement,
    OverviewGraphCashflow,  
    OverviewGraphBalance,
    OverviewGraphIncome,
    CompanyValuationTable,
    CompanyValuationPaybackTimeFreeCashflow,
    CompanyFinancialsIncomeGraph,
    CompanyFinancialsBalanceGraph,
    CompanyFinancialsCashflowGraph,
    CompanyValuationHistorical
  },
  data() {
    return {
      languageStrings: {
        en: {
          title: 'Onboarding',
          headline: 'A quick introduction',
          skip: 'Skip the onboarding',
          next: 'Next',
          back: 'Back',
          done: 'Done',
        },
        de: {
          title: 'Erste Schritte',
          headline: 'Eine kurze Einführung',
          skip: 'Onboarding überspringen',
          next: 'Weiter',
          back: 'Zurück',
          done: 'Fertig',
        }
      },
      step: 'start',
      numSteps: 3,
      companyDetail: null,
      companyValuation: null,
      companySECFilings: null,
      cashFlowData: null,
      cashFlowDataFull: null,
      balanceData: null,
      balanceDataFull: null,
      incomeData: null,
      incomeDataFull: null,
      marketCapData: null,
      reportedCurrency: '',
      unitOfAccount: 'USD',
      symbol: '',
      showAll: false,
    };
  },
  created() {
    this.language = sessionManager.read('language');
    this.unitOfAccount = sessionManager.read('unitOfAccount');

    // in the real onboarding we read the last step from the session
    if(this.trackSteps)
    {
      this.step = sessionManager.read('onboardingStep');
      this.symbol = sessionManager.read('onboardingSymbol');
      if(this.symbol != null && this.symbol != '')
      {
        this.fetchCompanyData();
      }
      logUserEvent('onboarding', sessionManager);
    }
    else
    {
      logUserEvent('tour', sessionManager);
    }
    document.title = this.strings['title'] + ' - Valuetion';

    // this might be a hack, but I don't want to force the users if there would be an issues
    if(this.step == 'done')
    {
      // we are done with the onboarding, go to the tour page, just to be on the safe side, give the user the option to use the menu  
      logUserEvent('onboarding', sessionManager, this.$vuetify.breakpoint.mobile, 'onboarding issue');
      this.$router.push('/tour');
    }

    if(this.showAll)
    {
      this.symbol = 'MSFT';
      this.fetchCompanyData();
    }
  },
  methods: {
    async gotoStep(step) {
      this.step = step;
      if(this.trackSteps)
      {
        await this.updateOnboardingStep(step);
      }      
      if(step == 'done')
      {
        await this.addToWatchlist();
        var escapedSymbol = this.symbol.replace('.', '_');
        this.$router.push('/profile/' + escapedSymbol);
      }
      window.scrollTo(0, 0);
    },
    companySearchSelected(value) {
      this.symbol = value;
      sessionManager.write('onboardingSymbol', value);
      this.gotoStep('moat');
      this.fetchCompanyData();
    },
    // add the company to the watchlist
    async addToWatchlist() {
      // send email and password to the API to login the user
      const url = '/api/HttpTriggerWatchlist';
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: 'application/json',
          'Content-Type' : 'application/json',
          'JWT' : sessionManager.read('token'),
        },
        body: JSON.stringify({
           action: 'add', 
           id: this.symbol, 
           finnub: sessionManager.read('useFinnhub')
        }),
      }).then((response, reject) => {
        if (response.status === 200) {
          // show the popup with the success message
          this.isOnWatchlist = true;
          logUserEvent('addToWatchlist', sessionManager, this.$vuetify.breakpoint.mobile, this.symbol);
        }
      });
    },
    async updateOnboardingStep(step)
    {
      sessionManager.write('onboardingStep', step);
      const url = '/api/HttpTriggerLogOnboardingEvent?';
      const response = await fetch(url +
        new URLSearchParams({
          step: step,
        }), { 
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type' : 'application/json',
          'JWT' : sessionManager.read('token'),
        },
      });
    },
    // method to get the company valuation
    async fetchCompanyData()
    {
      const urlFMP = '/api/HttpTriggerGetCompanyDataFMP?';
      const urlFH = '/api/HttpTriggerGetCompanyDataFH?';
      var url = '';

      if(sessionManager.read('useFinnhub'))
      {
        url = urlFH;
      }
      else
      {
        url = urlFMP;
      }
      
      const response = await fetch(url +
        new URLSearchParams({
          symbol: this.cleanTickerSymbol,
          unitOfAccount: this.unitOfAccount, 
          language: this.language,
        }), { 
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type' : 'application/json',
          'JWT' : sessionManager.read('token'),
        },
      });

      // check if response is ok
      if(response.ok) {
        const data = await response.json();
        this.companyValuation = data.valuation;
        this.companyDetail = data.profile;
        this.cashFlowData = { 
          cashflowAnnual: data.cashFlowAnnual, 
          cashflowQuarter: data.cashFlowQuarter, 
          cashflowTTM: data.cashFlowTTM, 
          hasTTM: data.hasTTM,
        };
        this.cashFlowDataFull = { 
          cashflowAnnual: data.cashFlowAnnualFull, 
          cashflowQuarter: data.cashFlowQuarterFull, 
          cashflowTTM: data.cashFlowTTMFull, 
          hasTTM: data.hasTTM,
          type: data.type,
        };
        this.incomeData = { 
          incomeAnnual: data.incomeAnnual, 
          incomeQuarter: data.incomeQuarter, 
          incomeTTM: data.incomeTTM,
          hasTTM: data.hasTTM,
        };
        this.incomeDataFull = { 
          incomeAnnual: data.incomeAnnualFull, 
          incomeQuarter: data.incomeQuarterFull, 
          incomeTTM: data.incomeTTMFull,
          hasTTM: data.hasTTM,
          type: data.type,
        };
        this.reportedCurrency = data.reportedCurrency;
        this.balanceData = { 
          balanceAnnual: data.balanceAnnual, 
          balanceQuarter: data.balanceQuarter,
        };
        this.balanceDataFull = { 
          balanceAnnual: data.balanceAnnualFull, 
          balanceQuarter: data.balanceQuarterFull,
          type: data.type,
        };
        this.isBank = data.type == "FHBank";
        this.marketCapData = data.marketCap;
      }
      else {
        // check if response is unauthorized
        if(response.status === 401) {
          // redirect to login page
          this.$router.push('/login');
        }
      }    
    },
  },
  computed: {
    strings() {
        return this.languageStrings[this.language];
    },
    cleanTickerSymbol()
    {
      return replaceUnderscoreWithDot(this.symbol);
    },
  },
}
</script>

<style scoped>
.button_container {
    position: relative;
    width: 100%; /* Adjust the width as needed */
}

.button-left, .button-right {
    position: absolute;
    bottom: 0px; /* Adjust distance from bottom */
}

.button-left {
    left: 10px; /* Adjust distance from left */
}

.button-right {
    right: 10px; /* Adjust distance from right */
}

</style>

<template>
  <div>
    <!-- step start -->
    <div v-if="showAll || step=='start'">
      <section :class="$vuetify.breakpoint.smAndDown ? 'py-v-xl px-v-xl' : 'py-v-3xl px-v-4xl'">
        <div class="flex justify-start items-center">
          <span class="flex-none pr-v-sm"><svg :width="$vuetify.breakpoint.smAndDown ? '44px' : '80px'" :height="$vuetify.breakpoint.smAndDown ? '44px' : '80px'" viewBox="0 0 7.9375 7.9375" version="1.1" id="svg5" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg"><defs id="defs2"><rect x="30" y="486" width="372.5" height="75.259857" id="rect1465-7-8" /></defs><g id="layer1" transform="translate(195.79166,-445.55834)"><circle style="fill:#71b224;fill-opacity:1;stroke-width:0.296467;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:6.8" id="path1525-7" cx="-191.82291" cy="449.52707" r="3.96875" /><g aria-label="v" transform="matrix(0.30397883,0,0,0.30397907,-203.69511,295.47429)" id="text1463-6-5" style="font-weight:bold;font-size:32px;line-height:1.25;font-family:'Plus Jakarta Sans';-inkscape-font-specification:'Plus Jakarta Sans Bold';letter-spacing:0px;word-spacing:0px;white-space:pre;shape-inside:url(#rect1465-7-8);display:inline;fill:#ffffff"><path d="m 37.2,516.3619 -6.848,-17.408 h 4.576 l 4.96,13.696 h -1.696 l 4.992,-13.696 h 4.576 l -6.848,17.408 z" id="path1322" /></g></g></svg>
          </span>
          <span class="darkest text-v-4xl font-pj pb-v-md sm:text-v-4xl md:text-v-5xl xl:text-v-6xl"><b>value</b>tion</span>
        </div>
        <div class="grid grid-cols-1 gap-x-v-xl sm:grid-cols-2">
          <div v-if="language=='de'">
            <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-2xl font-pj pb-v-md break-manual' : 'darkest text-v-4xl font-pj pb-v-xl break-manual'">
              <b>Willkommen bei Valuetion!</b> 
            </div>
            <div class="darkest text-v-base font-pj pb-v-xl">
              Hallo und herzlich willkommen bei Valuetion! 
              Wir freuen uns riesig, dass Du dabei bist. 
              Wie wäre es mit einer kurzen Tour, bevor es losgeht? 
              Es dauert nur ein paar Minuten. 
              Bereit? Klicke auf "Weiter" und lass uns starten!
            </div>
          </div>
          <div v-else>
            <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-2xl font-pj pb-v-md break-manual' : 'darkest text-v-4xl font-pj pb-v-xl break-manual'">
              <b>Welcome to Valuetion!</b>
            </div>
            <div class="darkest text-v-base font-pj pb-v-xl">
              Hi there! 
              We're super excited to have you join us at Valuetion! 
              Before diving in, how about a quick tour? It'll only take a few minutes. 
              Ready? Click "Next" and let's roll!
            </div>
          </div>
          <div>
            <div><img src="../assets/rocket.png" alt="image" style="height: 100%; width: 100%; max-height: 500px; object-fit: contain;" /></div>
          </div>
        </div>  
        <div class="button_container pt-v-3xl">
          <button type="button" class="button-right flex-none bg-primary border rounded-md border-solid border-primary regular text-lightest font-pj transition-all duration-200 py-v-sm px-v-md hover:bg-primary-light hover:border-primary-light" @click="gotoStep('under_construction')">
            {{ strings['next'] }}
          </button>
        </div>
      </section>
    </div>

    <!-- step under_construction -->
    <div v-if="showAll || step=='under_construction'">
      <section :class="$vuetify.breakpoint.smAndDown ? 'py-v-xl px-v-xl' : 'py-v-3xl px-v-4xl'">
        <div class="grid grid-cols-1 gap-x-v-xl sm:grid-cols-2">
          <div v-if="language=='de'">
            <div class="darkest text-v-4xl font-pj pb-v-xl break-manual">
              <b>Wir sind in der Beta-Phase :</b> Dein Feedback ist Gold wert
            </div>
            <div class="darkest text-v-base font-pj pb-v-xl">
              Du bist einer der Ersten in unserer Beta-Phase! 
              Wir arbeiten unermüdlich an der Verbesserung von Valuetion und schätzen Deine Meinung sehr. 
              Fragen? Kontaktiere uns jederzeit - unsere Kontaktdaten findest Du im Footer.
            </div>
            <div class="darkest text-v-base font-pj">
              Derzeit decken wir Aktien ab, die an den amerikanischen Börsen <b>NYSE</b> und <b>NASDAQ</b> gelistet sind.
              Das sind möglicherweise noch nicht alle Aktien die Du suchst, aber das sollte sich bald ändern.  
            </div>
          </div>
          <div v-else>
            <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-2xl font-pj pt-v-md pb-v-md break-manual' : 'darkest text-v-4xl font-pj pt-v-xl pb-v-md break-manual'">
              <b>We're in Bet:</b> Your Feedback Makes Us Better
            </div>
            <div class="darkest text-v-base font-pj pb-v-md">
              Guess what? You're among the first to explore our early beta phase.
              We're tirelessly enhancing Valuetion and would love your insights. 
              Got questions? Reach out anytime - our contact info is right in the footer.
            </div>
            <div class="darkest text-v-base font-pj">
              We currently cover and analyze stocks listed on the American <b>NYSE</b> and <b>NASDAQ</b> exchanges. We may not yet cover all the stocks you are looking for, but that should change soon.
            </div>
          </div>
          <div>
            <div><img src="../assets/under_construction.png" alt="image" style="height: 100%; width: 100%; max-height: 500px; object-fit: contain;" /></div>
          </div>
        </div>  
        <div class="button_container pt-v-3xl">
          <button type="button" class="button-left flex-none text-medium border rounded-md border-solid regular font-pj transition-all duration-200 py-v-sm px-v-md hover:text-medium-light" @click="gotoStep('start')">
            {{ strings['back'] }}
          </button>
          <button type="button" class="button-right flex-none bg-primary border rounded-md border-solid border-primary regular text-lightest font-pj transition-all duration-200 py-v-sm px-v-md hover:bg-primary-light hover:border-primary-light" @click="gotoStep('overview')">
            {{ strings['next'] }}
          </button>
        </div>
      </section>
    </div>

    <!-- step overview -->
    <div v-if="showAll || step=='overview'">
      <section :class="$vuetify.breakpoint.smAndDown ? 'py-v-xl px-v-xl' : 'py-v-3xl px-v-4xl'">
        <div class="grid grid-cols-1 gap-x-v-xl sm:grid-cols-2">
          <div v-if="language=='de'">
            <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-2xl font-pj pb-v-md break-manual' : 'darkest text-v-4xl font-pj pb-v-xl break-manual'">
              <b>Entdecke Value Investing:</b> mit Valuetion
            </div>
            <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-base font-pj  pb-v-xl' : 'darkest text-v-base font-pj max-w-2xl  pb-v-xl'">
              Willkommen in Deiner neuen Welt des Value Investings! Hier kannst Du Dich auf Folgendes freuen:
              <ul class="p-v-md">
                <li class="pt-v-md">
                  <b>Unternehmensprofile </b> mit <b>Bewertungen </b> inspiriert von Investment-Gurus
                </li>
                <li class="pt-v-md">
                  <b>Ein Screener-Tool</b> um unterbewertete Aktien zu finden
                </li>
                <li class="pt-v-md">
                  <b>Ein personalisiertes Dashboard</b> um Deine Lieblingsunternehmen zu verfolgen
                </li>
              </ul>
            </div>
          </div>
          <div v-else>
            <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-2xl font-pj pb-v-md break-manual' : 'darkest text-v-4xl font-pj pb-v-xl break-manual'">
              <b>Discover Value Investing:</b> with Valuetion
            </div>
            <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-base font-pj  pb-v-xl' : 'darkest text-v-base font-pj max-w-2xl  pb-v-xl'">
              Welcome to your new hub for value investing! Here's what you can look forward to:
              <ul class="p-v-md">
                <li class="pt-v-md">
                  <b>Company profiles</b> with <b>valuations</b> inspired by investing gurus
                </li>
                <li class="pt-v-md">
                  <b>A screener</b> tool to spot undervalued stocks
                </li>
                <li class="pt-v-md">
                  <b>A personalized dashboard</b> to track your favorite companies
                </li>
              </ul>               
            </div>
          </div>
          <div>
            <div><img src="../assets/symbol_search_500.gif" alt="image" style="height: 100%; width: 100%; max-height: 500px; object-fit: contain;" /></div>
          </div>
        </div>
        <div class="button_container pt-v-3xl">
          <button type="button" class="button-left flex-none text-medium border rounded-md border-solid regular font-pj transition-all duration-200 py-v-sm px-v-md hover:text-medium-light" @click="gotoStep('under_construction')">
            {{ strings['back'] }}
          </button>
          <button type="button" class="button-right flex-none bg-primary border rounded-md border-solid border-primary regular text-lightest font-pj transition-all duration-200 py-v-sm px-v-md hover:bg-primary-light hover:border-primary-light" @click="gotoStep('no_bias')">
            {{ strings['next'] }}
          </button>
        </div> 
      </section>
    </div>

    <!-- step no_bias -->
    <div v-if="showAll || step=='no_bias'">
      <section :class="$vuetify.breakpoint.smAndDown ? 'py-v-xl px-v-xl' : 'py-v-3xl px-v-4xl'">
        <div class="grid grid-cols-1 gap-x-v-xl sm:grid-cols-2">
          <div v-if="language=='en'">
            <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-2xl font-pj pb-v-md break-manual' : 'darkest text-v-4xl font-pj pb-v-xl break-manual'">
              <b>Beyond Shares:</b> Focus on the Company
            </div>
            <div class="darkest text-v-base font-pj pb-v-xl">
              Remember, investing in stocks means owning a piece of a company. 
              So, we present the full company metrics, and not just per share.
            </div>
            <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-2xl font-pj pt-v-md pb-v-md break-manual' : 'darkest text-v-4xl font-pj pt-v-xl pb-v-md break-manual'">
              <b>No price charts here:</b> no bias
            </div>
            <div class="darkest text-v-base font-pj">
              No price charts here - we believe in unbiased, fundamental analysis for a clearer perspective.
              We want to promote a relaxed and objective approach where yesterdays stock price does not matter and hope you agree with us.
            </div>
          </div>
          <div v-if="language=='de'">
            <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-2xl font-pj pb-v-md break-manual' : 'darkest text-v-4xl font-pj pb-v-xl break-manual'">
              <b>Mehr als nur Aktien:</b> Fokus auf das Unternehmen
            </div>
            <div class="darkest text-v-base font-pj pb-v-xl">
              Denke daran, dass Du mit Aktien einen Teil eines Unternehmens erwirbst. 
              Deshalb zeigen wir Dir die Kennzahlen nicht pro Aktie, sondern immer auf das gesamte Unternehmen.
            </div>
            <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-2xl font-pj pt-v-md pb-v-md break-manual' : 'darkest text-v-4xl font-pj pt-v-xl pb-v-md break-manual'">
              <b>Wir verzichten auf Preisdriagramme:</b> keine Charts
            </div>
            <div class="darkest text-v-base font-pj">
              Wir verzichten auf Preisdiagramme - wir setzen auf unvoreingenommene, fundamentale Analyse für eine klare Perspektive.
              Wir möchten einen entspannten und objektiven Ansatz fördern bei dem der Aktienkurs von gestern keine Rolle spielt und hoffen, dass Du uns zustimmst.
            </div>
          </div>
          <div>
            <div><img src="../assets/reading.png" alt="image" style="height: 100%; width: 100%; max-height: 500px; object-fit: contain;" /></div>
          </div>
        </div>  
        <div class="button_container pt-v-3xl">
          <button type="button" class="button-left flex-none text-medium border rounded-md border-solid regular font-pj transition-all duration-200 py-v-sm px-v-md hover:text-medium-light" @click="gotoStep('overview')">
            {{ strings['back'] }}
          </button>
          <button type="button" class="button-right flex-none bg-primary border rounded-md border-solid border-primary regular text-lightest font-pj transition-all duration-200 py-v-sm px-v-md hover:bg-primary-light hover:border-primary-light" @click="gotoStep('company_overview')">
            {{ strings['next'] }}
          </button>
        </div>
      </section>
    </div>

    <!-- step company_overview -->
    <div v-if="showAll || step=='company_overview'">
      <section :class="$vuetify.breakpoint.smAndDown ? 'py-v-xl px-v-xl' : 'py-v-3xl px-v-4xl'">
        <div class="grid grid-cols-1 gap-x-v-xl sm:grid-cols-2">
          <div v-if="language=='de'">
            <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-2xl font-pj pb-v-md break-manual' : 'darkest text-v-4xl font-pj pb-v-xl break-manual'">
              <b>Beginne Deine Reise:</b> Unternehmens&shy;übersicht
            </div>
            <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-base font-pj  pb-v-xl' : 'darkest text-v-base font-pj max-w-2xl  pb-v-xl'">
              Neugierig auf ein Unternehmen? 
              Einfach suchen und auf den Namen klicken, um einen Überblick zu erhalten. 
              Es ist der perfekte Startpunkt für Deine Investitionsanalyse.
            </div>                          
          </div>
          <div v-else>
            <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-2xl font-pj pb-v-md break-manual' : 'darkest text-v-4xl font-pj pb-v-xl break-manual'">
              <b>Start Your Journey:</b> Company Overview
            </div>
            <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-base font-pj  pb-v-xl' : 'darkest text-v-base font-pj max-w-2xl  pb-v-xl'">
              Curious about a company? Just search and click on its name to get a snapshot. 
              It's the perfect starting point for your investment analysis.
            </div> 
          </div>
          <div>
            <div class="pb-v-xl"><img src="../assets/msft_en.png" alt="image" style="height: 100%; width: 100%; max-height: 500px; object-fit: contain;" /></div>
          </div>
        </div>   
        <div v-if="language=='de'">
          <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-base font-pj  pb-v-xl' : 'darkest text-v-base font-pj max-w-2xl  pb-v-xl'">
            Suche Dir ein Unternehmen aus mit dem wir die Tour fortfahren sollen:
          </div>
          <div class="pb-v-xl">
            <CompanySearch v-on:input="companySearchSelected" />
          </div>     
        </div>
        <div v-else>
          <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-base font-pj  pb-v-xl' : 'darkest text-v-base font-pj max-w-2xl  pb-v-xl'">
            Pick a company to continue the tour:
          </div>
          <div class="pb-v-xl">
            <CompanySearch v-on:input="companySearchSelected" />
          </div>         
        </div>
        <div class="button_container pt-v-3xl">
          <button type="button" class="button-left flex-none text-medium border rounded-md border-solid regular font-pj transition-all duration-200 py-v-sm px-v-md hover:text-medium-light" @click="gotoStep('no_bias')">
            {{ strings['back'] }}
          </button>
        </div> 
      </section>
    </div>

     <!-- step moat -->
     <div v-if="showAll || step=='moat'">
      <section :class="$vuetify.breakpoint.smAndDown ? 'py-v-xl px-v-xl' : 'py-v-3xl px-v-4xl'">
        <div class="grid grid-cols-1 gap-x-v-xl sm:grid-cols-2">
          <div v-if="language=='de'">
            <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-2xl font-pj pb-v-md break-manual' : 'darkest text-v-4xl font-pj pb-v-xl break-manual'">
              <b>Burggraben verstehen:</b> Einblick in den Wettbewerbs&shy;vorteil
            </div>
            <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-base font-pj  pb-v-xl' : 'darkest text-v-base font-pj max-w-2xl  pb-v-xl'"> 
              Wir bringen den "Burggraben" eines Unternehmens zum Vorschein, indem wir die essentiellen Finanzdaten visualisieren. 
            </div>
            <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-base font-pj  pb-v-xl' : 'darkest text-v-base font-pj max-w-2xl  pb-v-xl'"> 
              Stelle Dir vor: Ein Unternehmen, das über Jahre hinweg Umsatz, Gewinn, operativen Cashflow und Buchwert steigert - das ist ein Zeichen für einen bedeutsamen Wettbewerbsvorteil. 
              Dieser "Burggraben" signalisiert Stärke und Stabilität. 
              Auch wenn einzelne Kennzahlen manchmal irreführend sein können, zeigt ein beständiger Aufwärtstrend in der Regel eine solide und robuste Unternehmensleistung.
            </div>       
          </div>
          <div v-else>
            <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-2xl font-pj pb-v-md break-manual' : 'darkest text-v-4xl font-pj pb-v-xl break-manual'">
              <b>Unveiling the Moat:</b> Insight into Competitive Advantage
            </div>
            <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-base font-pj  pb-v-xl' : 'darkest text-v-base font-pj max-w-2xl  pb-v-xl'"> 
              Let's illuminate the company's "moat" through fundamental financial data. 
            </div>
            <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-base font-pj  pb-v-xl' : 'darkest text-v-base font-pj max-w-2xl  pb-v-xl'"> 
              Picture this: A company consistently boosting its revenue, profits, operational cash flow, and book value over time likely harbors a significant competitive edge, often referred to as the "moat". 
              While individual metrics can sometimes be misleading, an overarching upward trend across these key financial indicators typically signals robust and resilient performance.
            </div>       
          </div>
          <div v-if="(companyValuation != null && companyDetail != null && unitOfAccount != null)" class="sm:px-v-xl py-v-md">
            <CompanyGrowth v-if="companyValuation" 
                v-bind:moatData="companyValuation.moat[0]" 
                v-bind:unitOfAccount="unitOfAccount"
                v-bind:logo="false"
                v-bind:logoText="companyDetail.companyName"/>
          </div>
          <div v-else class="flex flex-col">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </div>
        </div>  
        <div class="button_container pt-v-3xl">
          <button type="button" class="button-left flex-none text-medium border rounded-md border-solid regular font-pj transition-all duration-200 py-v-sm px-v-md hover:text-medium-light" @click="gotoStep('company_overview')">
            {{ strings['back'] }}
          </button>
          <button type="button" class="button-right flex-none bg-primary border rounded-md border-solid border-primary regular text-lightest font-pj transition-all duration-200 py-v-sm px-v-md hover:bg-primary-light hover:border-primary-light" @click="gotoStep('management')">
            {{ strings['next'] }}
          </button>
        </div>
      </section>
    </div>

    <!-- step management -->
    <div v-if="showAll || step=='management'">
      <section :class="$vuetify.breakpoint.smAndDown ? 'py-v-xl px-v-xl' : 'py-v-3xl px-v-4xl'">
        <div class="grid grid-cols-1 gap-x-v-xl sm:grid-cols-2">
          <div v-if="language=='de'">
            <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-2xl font-pj pb-v-md break-manual' : 'darkest text-v-4xl font-pj pb-v-xl break-manual'">
              <b>Management&shy;qualität</b> bewerten
            </div>
            <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-base font-pj  pb-v-xl' : 'darkest text-v-base font-pj max-w-2xl  pb-v-xl'">
              Die Effektivität des Managements beurteilen? Schau Dir die folgenden Kennzahlen an.
              Sie verraten viel über das Geschick des Managements.
            </div>
            <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-base font-pj  pb-v-xl' : 'darkest text-v-base font-pj max-w-2xl  pb-v-xl'">
              <ul class="p-v-md">
                <li class="">
                  <b>Rendite</b>: Die Eigenkapitalrendite (ROE) und die Kapitalrendite (ROIC) dienen als Indikatoren für die Managementqualität. Höhere Renditen deuten auf eine bessere Managementleistung hin.
                </li>
                <li class="pt-v-md">
                  <b>Schulden</b>: Zusätzliche Schulden bieten Investitionsmöglichkeiten, die Einnahmen generieren können. Es ist jedoch wichtig, dass das Unternehmen seine Schuldenverpflichtungen erfüllen kann.
                </li>
                <li class="pt-v-md">
                  <b>Ausschüttungen an Aktionäre</b>: Wenn das Unternehmen keine rentablen Investitionsmöglichkeiten hat, sollten die Gewinne an die Aktionäre ausgeschüttet werden, entweder in Form von Dividenden oder Aktienrückkäufen.
                </li>
              </ul>
            </div>       
          </div>
          <div v-else>
            <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-2xl font-pj pb-v-md break-manual' : 'darkest text-v-4xl font-pj pb-v-xl break-manual'">
              <b>Management Quality:</b> Evaluating from Financial Data
            </div>
            <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-base font-pj  pb-v-xl' : 'darkest text-v-base font-pj max-w-2xl  pb-v-xl'">
              Deciphering management's effectiveness? 
              Look at ROE and ROIC metrics, debt management, and how they reward shareholders. It tells a lot about their savvy.
            </div>
            <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-base font-pj  pb-v-xl' : 'darkest text-v-base font-pj max-w-2xl  pb-v-xl'">
              <ul class="p-v-md">
                <li class="">
                  <b>Return</b>: Return: Return on Equity (ROE) and Return on Invested Capital (ROIC) serve as indicators of management quality. Higher returns point towards better management performance.
                </li>
                <li class="pt-v-md">
                  <b>Debt</b>: Additional debt provides investment opportunities that can generate revenue. However, it's crucial that the company can manage its debt obligations.
                </li>
                <li class="pt-v-md">
                  <b>Shareholder Paybacks</b>: If the company lacks profitable investment opportunities, profits should be distributed to shareholders, either in the form of dividends or share buybacks.
                </li>
              </ul>
            </div>       
          </div>
          <div v-if="(companyValuation != null && companyDetail != null && unitOfAccount != null)" class="sm:px-v-xl py-v-md">
            <CompanyManagement v-if="companyValuation" 
                v-bind:managementData="companyValuation.management" 
                v-bind:unitOfAccount="unitOfAccount"
                v-bind:logo="false"
                v-bind:logoText="companyDetail.companyName"/>
          </div>
          <div v-else class="flex flex-col">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </div>

        </div>  
        <div class="button_container pt-v-3xl">
          <button type="button" class="button-left flex-none text-medium border rounded-md border-solid regular font-pj transition-all duration-200 py-v-sm px-v-md hover:text-medium-light" @click="gotoStep('moat')">
            {{ strings['back'] }}
          </button>
          <button type="button" class="button-right flex-none bg-primary border rounded-md border-solid border-primary regular text-lightest font-pj transition-all duration-200 py-v-sm px-v-md hover:bg-primary-light hover:border-primary-light" @click="gotoStep('overview_cashflow')">
            {{ strings['next'] }}
          </button>
        </div>
      </section>
    </div>

    <!-- step overview_cashflow -->
    <div v-if="showAll || step=='overview_cashflow'">
      <section :class="$vuetify.breakpoint.smAndDown ? 'py-v-xl px-v-xl' : 'py-v-3xl px-v-4xl'">
        <div class="grid grid-cols-1 gap-x-v-xl sm:grid-cols-2">
          <div v-if="language=='de'">
            <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-2xl font-pj pb-v-md break-manual' : 'darkest text-v-4xl font-pj pb-v-xl break-manual'">
              <b>Einblicke in Cashflows:</b> Wo das Geld hinfließt
            </div>
            <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-base font-pj  pb-v-xl' : 'darkest text-v-base font-pj max-w-2xl  pb-v-xl'">
              Schaffe Dir einen Überblick über die Geldströme des Unternehmens. 
              Wir zeigen den operativen und freien Cashflow und wie Dividenden und Aktienrückkäufe gehandhabt werden.
            </div>
            <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-base font-pj  pb-v-xl' : 'darkest text-v-base font-pj max-w-2xl  pb-v-xl'">
              <ul class="p-v-md">
                <li class="">
                  <b>Operativer Cashflow</b>: Der Cashflow, der aus den normalen Geschäftstätigkeiten eines Unternehmens generiert wird. Er wird berechnet, indem nicht zahlungswirksame Aufwendungen zum Nettogewinn addiert werden.
                </li>
                <li class="pt-v-md">
                  <b>Freier Cashflow</b>: Der Cashflow, der von einem Unternehmen generiert wird, nachdem die Investitionen in Sachanlagen vom operativen Cashflow abgezogen wurden, steht für Wachstum oder Rückzahlungen an die Aktionäre zur Verfügung.
                </li>
                <li class="pt-v-md">
                  <b>Aktienrückkäufe</b>: Aktienrückkäufe sind eine Möglichkeit für Unternehmen, Kapital an die Aktionäre zurückzugeben. Sie sind besonders attraktiv bei niedrigen Aktienkursen, da die Aktionäre mehr Aktien für ihr Geld erhalten.
                </li>
                <li class="pt-v-md">
                  <b>Dividenden</b>: Dividenden sind eine Möglichkeit für Unternehmen, Kapital an die Aktionäre durch direkte Barzahlungen zurückzugeben.
                </li>
              </ul>
            </div>            
          </div>
          <div v-else>
            <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-2xl font-pj pb-v-md break-manual' : 'darkest text-v-4xl font-pj pb-v-xl break-manual'">
              <b>Cashflow Insights:</b> Where Money Flows
            </div>
            <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-base font-pj  pb-v-xl' : 'darkest text-v-base font-pj max-w-2xl  pb-v-xl'">
              Dive into how companies generate and spend cash. It's all about the operational and free cash flows, and how they handle dividends and buybacks.
            </div>
            <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-base font-pj  pb-v-xl' : 'darkest text-v-base font-pj max-w-2xl  pb-v-xl'"> 
              <ul class="p-v-md">
                <li class="">
                  <b>Operating Cashflow</b>: Cash generated from a company's normal business operations. It is calculated by adding non-cash expenses to net income.
                </li>
                <li class="pt-v-md">
                  <b>Free Cashflow</b>: Cash generated by a company after deducting capital expenditures from its operating cash flow, ready to be used for growth or shareholder paybacks.
                </li>
                <li class="pt-v-md">
                  <b>Share buybacks</b>: Share buybacks are a way for companies to return capital to shareholders. They are particularly attractive at low share prices, as shareholders get more shares for their money.
                </li>
                <li class="pt-v-md">
                  <b>Dividends</b>: Dividends are a way for companies to return capital to shareholders by direct cash payments. 
                </li>
              </ul>
            </div>            
          </div>
          <div v-if="(companyValuation != null && companyDetail != null && unitOfAccount != null)" class="sm:px-v-xl py-v-md">
            <OverviewGraphCashflow v-if="cashFlowData && unitOfAccount && companyDetail && companyDetail.isValid" 
                v-bind:cashFlowData="cashFlowData" 
                v-bind:unitOfAccount="unitOfAccount"
                v-bind:logo="false"
                v-bind:companyName="companyDetail.companyName"/>
          </div>
          <div v-else class="flex flex-col">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </div>
        </div>  
        <div class="button_container pt-v-3xl">
          <button type="button" class="button-left flex-none text-medium border rounded-md border-solid regular font-pj transition-all duration-200 py-v-sm px-v-md hover:text-medium-light" @click="gotoStep('management')">
            {{ strings['back'] }}
          </button>
          <button type="button" class="button-right flex-none bg-primary border rounded-md border-solid border-primary regular text-lightest font-pj transition-all duration-200 py-v-sm px-v-md hover:bg-primary-light hover:border-primary-light" @click="gotoStep('overview_income')">
            {{ strings['next'] }}
          </button>
        </div>
      </section>
    </div>
    <!-- step overview_income -->
    <div v-if="showAll || step=='overview_income'">
      <section :class="$vuetify.breakpoint.smAndDown ? 'py-v-xl px-v-xl' : 'py-v-3xl px-v-4xl'">
        <div class="grid grid-cols-1 gap-x-v-xl sm:grid-cols-2">
          <div v-if="language=='de'">
            <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-2xl font-pj pb-v-md break-manual' : 'darkest text-v-4xl font-pj pb-v-xl break-manual'">
              <b>Einkommen und Umsatz:</b> Ein Überblick
            </div>
            <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-base font-pj  pb-v-xl' : 'darkest text-v-base font-pj max-w-2xl  pb-v-xl'">
              Vom Gesamtumsatz bis zum Reingewinn – erhalte einen klaren Überblick über die Gewinn und Verlustrechnung eines Unternehmens.
            </div>
            <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-base font-pj  pb-v-xl' : 'darkest text-v-base font-pj max-w-2xl  pb-v-xl'">
              <ul class="p-v-md">
                <li class="">
                  <b>Umsatz</b>: Gesamtbetrag, den ein Unternehmen von seinen Kunden für den Verkauf von Waren oder Dienstleistungen erhält.
                </li>
                <li class="pt-v-md">
                  <b>Nettogewinn</b>: Geld, das ein Unternehmen übrig hat, nachdem es alle Ausgaben von seinem Umsatz abgezogen hat. Es wird auch als Gewinn oder Ertrag bezeichnet.
                </li>
              </ul>
            </div>               
          </div>
          <div v-else>
            <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-2xl font-pj pb-v-md break-manual' : 'darkest text-v-4xl font-pj pb-v-xl break-manual'">
              <b>Income and Revenue:</b> The Financial Story
            </div>
            <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-base font-pj  pb-v-xl' : 'darkest text-v-base font-pj max-w-2xl  pb-v-xl'">
              From top-line revenue to bottom-line net income, get a clear view of a company's financial journey with our overview graphs.
            </div>
            <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-base font-pj  pb-v-xl' : 'darkest text-v-base font-pj max-w-2xl  pb-v-xl'">
              <ul class="p-v-md">
                <li class="">
                  <b>Revenue</b>: Total amount of money a company receives from its customers in exchange for the sales of goods or services. 
                </li>
                <li class="pt-v-md">
                  <b>Net Income</b>: Money a company has left after subtracting all expenses from its revenue. It is also known as profit or earnings.
                </li>
              </ul>
            </div>               
          </div>
          <div v-if="(companyValuation != null && companyDetail != null && unitOfAccount != null)" class="sm:px-v-xl py-v-md">
            <OverviewGraphIncome v-if="incomeData && unitOfAccount" 
                v-bind:incomeData="incomeData" 
                v-bind:unitOfAccount="unitOfAccount"
                v-bind:logo="false"
                v-bind:companyName="companyDetail.companyName"/>
          </div>
          <div v-else class="flex flex-col">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </div>
        </div>  
        <div class="button_container pt-v-3xl">
          <button type="button" class="button-left flex-none text-medium border rounded-md border-solid regular font-pj transition-all duration-200 py-v-sm px-v-md hover:text-medium-light" @click="gotoStep('overview_cashflow')">
            {{ strings['back'] }}
          </button>
          <button type="button" class="button-right flex-none bg-primary border rounded-md border-solid border-primary regular text-lightest font-pj transition-all duration-200 py-v-sm px-v-md hover:bg-primary-light hover:border-primary-light" @click="gotoStep('overview_balance')">
            {{ strings['next'] }}
          </button>
        </div>
      </section>
    </div>
        <!-- step overview_balance -->
        <div v-if="showAll || step=='overview_balance'">
      <section :class="$vuetify.breakpoint.smAndDown ? 'py-v-xl px-v-xl' : 'py-v-3xl px-v-4xl'">
        <div class="grid grid-cols-1 gap-x-v-xl sm:grid-cols-2">
          <div v-if="language=='de'">
            <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-2xl font-pj pb-v-md break-manual' : 'darkest text-v-4xl font-pj pb-v-xl break-manual'">
              <b>Bilanz</b> auf einen Blick
            </div>
            <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-base font-pj  pb-v-xl' : 'darkest text-v-base font-pj max-w-2xl  pb-v-xl'">
              Ein gesundes Unternehmen zeigt steigende Barmittel und sinkende Schulden. Wir machen es Dir einfach, deren finanzielle Gesundheit mit unserer Übersicht zu verstehen.
            </div>
            <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-base font-pj  pb-v-xl' : 'darkest text-v-base font-pj max-w-2xl  pb-v-xl'">
              <ul class="p-v-md">
                <li class="">
                  <b>Bargeld und kurzfristige Investitionen</b>: Liquide Mittel, die innerhalb eines Jahres in Bargeld umgewandelt werden können.
                </li>
                <li class="pt-v-md">
                  <b>Gesamtschulden</b>: Summe aller finanziellen Verpflichtungen eines Unternehmens. Sie wird auch als Gesamtverbindlichkeiten bezeichnet.
                </li>
              </ul>
            </div>             
          </div>
          <div v-else>
            <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-2xl font-pj pb-v-md break-manual' : 'darkest text-v-4xl font-pj pb-v-xl break-manual'">
              <b>Balance Sheet:</b> Breakdown
            </div>
            <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-base font-pj  pb-v-xl' : 'darkest text-v-base font-pj max-w-2xl  pb-v-xl'">
              A healthy company shows increasing cash and decreasing debt. We make it simple to understand their financial health with our overview graphs.
            </div>
            <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-base font-pj  pb-v-xl' : 'darkest text-v-base font-pj max-w-2xl  pb-v-xl'">
              <ul class="p-v-md">
                <li class="">
                  <b>Cash and short term investments</b>: Liquid assets that can be converted into cash within a year.
                </li>
                <li class="pt-v-md">
                  <b>Total debt</b>: Sum of all financial obligations of a company. It is also known as total liabilities.
                </li>
              </ul>
            </div>             
          </div>
          <div v-if="(companyValuation != null && companyDetail != null && unitOfAccount != null)" class="sm:px-v-xl py-v-md">
            <OverviewGraphBalance v-if="balanceData && unitOfAccount" 
                v-bind:balanceData="balanceData" 
                v-bind:unitOfAccount="unitOfAccount"
                v-bind:logo="false"
                v-bind:companyName="companyDetail.companyName"/>
          </div>
          <div v-else class="flex flex-col">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </div>
        </div>  
        <div class="button_container pt-v-3xl">
          <button type="button" class="button-left flex-none text-medium border rounded-md border-solid regular font-pj transition-all duration-200 py-v-sm px-v-md hover:text-medium-light" @click="gotoStep('overview_income')">
            {{ strings['back'] }}
          </button>
          <button type="button" class="button-right flex-none bg-primary border rounded-md border-solid border-primary regular text-lightest font-pj transition-all duration-200 py-v-sm px-v-md hover:bg-primary-light hover:border-primary-light" @click="gotoStep('valuations')">
            {{ strings['next'] }}
          </button>
        </div>
      </section>
    </div>

    <!-- step valuations -->
    <div v-if="showAll || step=='valuations'">
      <section :class="$vuetify.breakpoint.smAndDown ? 'py-v-xl px-v-xl' : 'py-v-3xl px-v-4xl'">
        <div class="grid grid-cols-1 gap-x-v-xl sm:grid-cols-2">
          <div v-if="language=='de'">
            <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-2xl font-pj pb-v-md break-manual' : 'darkest text-v-4xl font-pj pb-v-xl break-manual'">
              <b>Bewertung:</b> Die Kunst der Schätzung
            </div>
            <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-base font-pj  pb-v-xl' : 'darkest text-v-base font-pj max-w-2xl  pb-v-xl'">
              Entdecke verschiedene Bewertungsmethoden, von klassischen Kennzahlen bis hin zu Techniken, die von Value-Investing-Legenden bevorzugt in ihren Büchern beschrieben werden.
            </div>
            <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-base font-pj' : 'darkest text-v-base font-pj max-w-2xl'">
              <ul class="p-v-md">
                <li class="">
                  <b>"Invested"</b>: von Danielle Town &amp; Phil Town.
                </li>
                <li class="pt-v-md">
                  <b>"Rule #1"</b> &amp; <b>"Payback time"</b>:  von Phil Town
                </li>
                <li class="pt-v-md">
                  <b>"The Intelligent Investor"</b>: von Benjamin Graham
                </li>
                <li class="pt-v-md">
                  <b>"Security Analysis"</b>: von Benjamin Graham und David L. Dodd
                </li>
                <li class="pt-v-md pb-v-md">
                  <b>"One Up On Wall Street"</b>:  von Peter Lynch
                </li>
              </ul>
            </div>                
          </div>
          <div v-else>
            <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-2xl font-pj pb-v-md break-manual' : 'darkest text-v-4xl font-pj pb-v-xl break-manual'">
              <b>Valuation:</b> The Art of Estimation
            </div>
            <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-base font-pj  pb-v-xl' : 'darkest text-v-base font-pj max-w-2xl  pb-v-xl'">
              Explore diverse valuation methods, from classic ratios to techniques championed by value investing legends from their books, such as:
            </div>
            <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-base font-pj' : 'darkest text-v-base font-pj max-w-2xl'">
              <ul class="p-v-md">
                <li class="">
                  <b>"Invested"</b>: by Danielle Town &amp; Phil Town.
                </li>
                <li class="pt-v-md">
                  <b>"Rule #1"</b> &amp; <b>"Payback time"</b>:  by Phil Town
                </li>
                <li class="pt-v-md">
                  <b>"The Intelligent Investor"</b>: by Benjamin Graham
                </li>
                <li class="pt-v-md">
                  <b>"Security Analysis"</b>: by Benjamin Graham and David L. Dodd
                </li>
                <li class="pt-v-md pb-v-md">
                  <b>"One Up On Wall Street"</b>:  by Peter Lynch
                </li>
              </ul>
            </div>                
          </div>
          <div v-if="(companyValuation != null && companyDetail != null && unitOfAccount != null)" class="sm:px-v-xl py-v-md">
            <CompanyValuationTable v-if="companyValuation && companyValuation.isValid && companyDetail && companyDetail.isValid" 
                v-bind:symbol="cleanTickerSymbol" 
                v-bind:valuation="companyValuation" 
                v-bind:marketCap="companyDetail.marketCap" 
                v-bind:unitOfAccount="unitOfAccount"
                v-bind:logo="false"
                v-bind:logoText="companyDetail.companyName"/>
          </div>
          <div v-else class="flex flex-col">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </div>
        </div>  
        <div class="button_container pt-v-3xl">
          <button type="button" class="button-left flex-none text-medium border rounded-md border-solid regular font-pj transition-all duration-200 py-v-sm px-v-md hover:text-medium-light" @click="gotoStep('overview_balance')">
            {{ strings['back'] }}
          </button>
          <button type="button" class="button-right flex-none bg-primary border rounded-md border-solid border-primary regular text-lightest font-pj transition-all duration-200 py-v-sm px-v-md hover:bg-primary-light hover:border-primary-light" @click="gotoStep('valuation_payback_time')">
            {{ strings['next'] }}
          </button>
        </div>
      </section>
    </div>

    <!-- step valuations -->
    <div v-if="showAll || step=='valuation_payback_time'">
      <section :class="$vuetify.breakpoint.smAndDown ? 'py-v-xl px-v-xl' : 'py-v-3xl px-v-4xl'">
        <div class="grid grid-cols-1 gap-x-v-xl sm:grid-cols-2">
          <div v-if="language=='de'">
            <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-2xl font-pj pb-v-md break-manual' : 'darkest text-v-4xl font-pj pb-v-xl break-manual'">
              <b>Payback-Time Bewertung:</b> Ein praktisches Beispiel
            </div>
            <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-base font-pj pb-v-xl' : 'darkest text-v-base font-pj max-w-2xl  pb-v-xl'">
              Jemals über den wahren Wert Deiner Investitionen nachgedacht? Der Payback-Time-Ansatz, basierend auf dem freien Cashflow, gibt Dir eine praktische Perspektive.
            </div>
            <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-base font-pj pb-v-xl' : 'darkest text-v-base font-pj max-w-2xl  pb-v-xl'">
              Die Payback-Time Methode aus dem Buch "Invested" basiert auf dem freien Cashflow und konzentriert sich darauf, wie viele Jahre es dauert, um die anfängliche Investition zurückzuerhalten.
              Wir schätzen eine Wachstumsrate für den freien Cashflow und addieren ihn über die nächsten 8 Jahre. 
              Nachdem wir Bargeld/Schulden verrechnet haben, ist die resultierende Zahl die Payback-Time Bewertung des Unternehmens.
            </div>       
          </div>
          <div v-else>
            <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-2xl font-pj pb-v-md break-manual' : 'darkest text-v-4xl font-pj pb-v-xl break-manual'">
              <b>Payback-Time Valuation:</b> A Practical Example
            </div>
            <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-base font-pj pb-v-xl' : 'darkest text-v-base font-pj max-w-2xl  pb-v-xl'">
              Ever wondered about the real value of your investments? The Payback-Time approach, based on free cash flow, gives you a practical perspective.
            </div>
            <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-base font-pj pb-v-xl' : 'darkest text-v-base font-pj max-w-2xl  pb-v-xl'">
              The Payback-Time Method from the book "Invested" is based on free cash flow and focuses on how many years it takes to recoup the initial investment.
              We estimate a growth rate for the free cash flow and accumulate it over the next 8 years. After adding the net cash/debt, the resulting number is the payback-time valuation of the company.
            </div>       
          </div>
          <div v-if="(companyValuation != null && companyDetail != null && unitOfAccount != null)" class="sm:px-v-xl py-v-md">
            <CompanyValuationPaybackTimeFreeCashflow v-if="companyValuation && companyValuation.isValid && companyDetail && companyDetail.isValid" 
                v-bind:valuation="companyValuation.paybackTimeValuationFreeCashflow[0]" 
                v-bind:marketCap="companyDetail.marketCap" 
                v-bind:unitOfAccount="unitOfAccount"
                v-bind:logo="false"
                v-bind:logoText="companyDetail.companyName"/>
          </div>
          <div v-else class="flex flex-col">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </div>
        </div>  
        <div class="button_container pt-v-3xl">
          <button type="button" class="button-left flex-none text-medium border rounded-md border-solid regular font-pj transition-all duration-200 py-v-sm px-v-md hover:text-medium-light" @click="gotoStep('valuations')">
            {{ strings['back'] }}
          </button>
          <button type="button" class="button-right flex-none bg-primary border rounded-md border-solid border-primary regular text-lightest font-pj transition-all duration-200 py-v-sm px-v-md hover:bg-primary-light hover:border-primary-light" @click="gotoStep('financials_income')">
            {{ strings['next'] }}
          </button>
        </div>
      </section>
    </div>

    <!-- step financials_income -->
    <div v-if="showAll || step=='financials_income'">
      <section :class="$vuetify.breakpoint.smAndDown ? 'py-v-xl px-v-xl' : 'py-v-3xl px-v-4xl'">
        <div class="grid grid-cols-1 gap-x-v-xl sm:grid-cols-2">
          <div v-if="language=='de'">
            <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-2xl font-pj pb-v-md break-manual' : 'darkest text-v-4xl font-pj pb-v-xl break-manual'">
              <b>Finanzberichte leicht gemacht:</b> Gewinn- und Verlustrechnung
            </div>
            <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-base font-pj  pb-v-xl' : 'darkest text-v-base font-pj max-w-2xl  pb-v-xl'">
              Gewinn- und Verlustrechnung, Bilanz, Cashflow – wir verwandeln diese Berichte in verständliche Diagramme für einen schnellen Finanzcheck.
            </div>
            <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-base font-pj  pb-v-xl' : 'darkest text-v-base font-pj max-w-2xl  pb-v-xl'">
              Die Gewinn- und Verlustrechnung zeigt die Umsätze, Ausgaben und Gewinne/Verluste, die während des Zeitraums erzielt wurden. 
              Sie gibt den Anlegern Einblick in die Leistung des Unternehmens.
            </div>       
          </div>
          <div v-else>
            <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-2xl font-pj pb-v-md break-manual' : 'darkest text-v-4xl font-pj pb-v-xl break-manual'">
              <b>Financials made easy:</b> Income Statement
            </div>
            <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-base font-pj  pb-v-xl' : 'darkest text-v-base font-pj max-w-2xl  pb-v-xl'">
              Income, balance, cash flow - we turn these statements into understandable graphs for a quick financial health check.
            </div>
            <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-base font-pj  pb-v-xl' : 'darkest text-v-base font-pj max-w-2xl  pb-v-xl'">
              The income statement shows the revenues, expenses, and profits/losses generated during the period. It provides investors with insight into how well the company is performing.
            </div>       
          </div>
          <div v-if="(companyValuation != null && companyDetail != null && unitOfAccount != null)">
            <CompanyFinancialsIncomeGraph 
                  v-if="incomeDataFull" 
                  v-bind:income="incomeDataFull" 
                  v-bind:companyName="companyDetail.companyName" 
                  v-bind:exchangeShortName="companyDetail.exchangeShortName"
                  v-bind:symbol="companyDetail.id"
                  v-bind:unitOfAccount="unitOfAccount"
                  v-bind:logo="false"
                  v-bind:navigation="false"
                  v-bind:allowSave="true"/>
          </div>
          <div v-else class="flex flex-col">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </div>
        </div>  
        <div class="button_container pt-v-3xl">
          <button type="button" class="button-left flex-none text-medium border rounded-md border-solid regular font-pj transition-all duration-200 py-v-sm px-v-md hover:text-medium-light" @click="gotoStep('valuation_payback_time')">
            {{ strings['back'] }}
          </button>
          <button type="button" class="button-right flex-none bg-primary border rounded-md border-solid border-primary regular text-lightest font-pj transition-all duration-200 py-v-sm px-v-md hover:bg-primary-light hover:border-primary-light" @click="gotoStep('financials_balance')">
            {{ strings['next'] }}
          </button>
        </div>
      </section>
    </div>

    <!-- step financials_balance -->
    <div v-if="showAll || step=='financials_balance'">
      <section :class="$vuetify.breakpoint.smAndDown ? 'py-v-xl px-v-xl' : 'py-v-3xl px-v-4xl'">
        <div class="grid grid-cols-1 gap-x-v-xl sm:grid-cols-2">
          <div v-if="language=='de'">
            <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-2xl font-pj pb-v-md break-manual' : 'darkest text-v-4xl font-pj pb-v-xl break-manual'">
              <b>Finanzberichte leicht gemacht:</b> Bilanz
            </div> 
            <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-base font-pj  pb-v-xl' : 'darkest text-v-base font-pj max-w-2xl  pb-v-xl'">
              Die Bilanz zeigt die Vermögenswerte, Verbindlichkeiten und das Eigenkapital des Unternehmens. 
              Sie gibt den Anlegern Einblick in die finanzielle Lage des Unternehmens.
            </div>
          </div>
          <div v-else>
            <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-2xl font-pj pb-v-md break-manual' : 'darkest text-v-4xl font-pj pb-v-xl break-manual'">
              <b>Financials made easy:</b> Balance Statement
            </div>      
            <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-base font-pj  pb-v-xl' : 'darkest text-v-base font-pj max-w-2xl  pb-v-xl'">
              The balance statement shows the assets, liabilities, and equity of the company. It provides investors with insight into how well the company is performing.
            </div>
          </div>
          <div v-if="(companyValuation != null && companyDetail != null && unitOfAccount != null)">
            <CompanyFinancialsBalanceGraph 
                  v-if="balanceDataFull" 
                  v-bind:balance="balanceDataFull" 
                  v-bind:companyName="companyDetail.companyName" 
                  v-bind:exchangeShortName="companyDetail.exchangeShortName"
                  v-bind:symbol="companyDetail.id"
                  v-bind:unitOfAccount="unitOfAccount"
                  v-bind:logo="false"
                  v-bind:navigation="false"
                  v-bind:allowSave="true"/>
          </div>
          <div v-else class="flex flex-col">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </div>
        </div>  
        <div class="button_container pt-v-3xl">
          <button type="button" class="button-left flex-none text-medium border rounded-md border-solid regular font-pj transition-all duration-200 py-v-sm px-v-md hover:text-medium-light" @click="gotoStep('financials_income')">
            {{ strings['back'] }}
          </button>
          <button type="button" class="button-right flex-none bg-primary border rounded-md border-solid border-primary regular text-lightest font-pj transition-all duration-200 py-v-sm px-v-md hover:bg-primary-light hover:border-primary-light" @click="gotoStep('financials_cashflow')">
            {{ strings['next'] }}
          </button>
        </div>
      </section>
    </div>

    <!-- step financials_cashflow -->
    <div v-if="showAll || step=='financials_cashflow'">
      <section :class="$vuetify.breakpoint.smAndDown ? 'py-v-xl px-v-xl' : 'py-v-3xl px-v-4xl'">
        <div class="grid grid-cols-1 gap-x-v-xl sm:grid-cols-2">
          <div v-if="language=='de'">
            <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-2xl font-pj pb-v-md break-manual' : 'darkest text-v-4xl font-pj pb-v-xl break-manual'">
              <b>Finanzberichte leicht gemacht:</b> Kapitalflussrechnung
            </div>   
            <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-base font-pj  pb-v-xl' : 'darkest text-v-base font-pj max-w-2xl  pb-v-xl'">
              Die Kapitalflussrechnung zeigt den im Zeitraum generierten und ausgegebenen Cashflow. Sie gibt den Anlegern Einblick in die Kapitalflüsse des Unternehmens.
            </div>    
          </div>
          <div v-else>
            <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-2xl font-pj pb-v-md break-manual' : 'darkest text-v-4xl font-pj pb-v-xl break-manual'">
              <b>Financials made easy:</b> Cashflow Statement
            </div> 
            <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-base font-pj  pb-v-xl' : 'darkest text-v-base font-pj max-w-2xl  pb-v-xl'">
              The cashflow statement shows the cash generated and spent during the period. It provides investors with insight into how the cash flows through the company.
            </div>    
          </div>
          <div v-if="(companyValuation != null && companyDetail != null && unitOfAccount != null)">
            <CompanyFinancialsCashflowGraph 
                v-if="cashFlowDataFull" 
                v-bind:cashflow="cashFlowDataFull" 
                v-bind:companyName="companyDetail.companyName" 
                v-bind:exchangeShortName="companyDetail.exchangeShortName" 
                v-bind:symbol="companyDetail.id"
                v-bind:unitOfAccount="unitOfAccount"
                v-bind:logo="false"
                v-bind:navigation="false"
                v-bind:allowSave="true"/>
          </div>
          <div v-else class="flex flex-col">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </div>
        </div>  
        <div class="button_container pt-v-3xl">
          <button type="button" class="button-left flex-none text-medium border rounded-md border-solid regular font-pj transition-all duration-200 py-v-sm px-v-md hover:text-medium-light" @click="gotoStep('financials_balance')">
            {{ strings['back'] }}
          </button>
          <button type="button" class="button-right flex-none bg-primary border rounded-md border-solid border-primary regular text-lightest font-pj transition-all duration-200 py-v-sm px-v-md hover:bg-primary-light hover:border-primary-light" @click="gotoStep('export_graphics')">
            {{ strings['next'] }}
          </button>
        </div>
      </section>
    </div>

    <!-- step export and share -->
    <div v-if="showAll || step=='export_graphics'">
      <section :class="$vuetify.breakpoint.smAndDown ? 'py-v-xl px-v-xl' : 'py-v-3xl px-v-4xl'">
        <div class="grid grid-cols-1 gap-x-v-xl sm:grid-cols-2">
          <div v-if="language=='de'">
            <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-2xl font-pj pb-v-md break-manual' : 'darkest text-v-4xl font-pj pb-v-xl break-manual'">
              <b>Grafiken für Social Media:</b> Einfach exportieren
            </div>   
            <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-base font-pj  pb-v-xl' : 'darkest text-v-base font-pj max-w-2xl  pb-v-xl'">
              Du bist gerne dazu eingeladen unsere Grafiken auf Social Media in Deinen Posts oder Videos zu verwenden und als Teil Deiner Analysen einzubinden. Natürlich freuen wir uns auch über eine Erwähnung von Valuetion.
            </div>
            <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-base font-pj  pb-v-xl' : 'darkest text-v-base font-pj max-w-2xl  pb-v-xl'">
              Die Grafiken auf Valuetion haben einen kleinen Export-Button <v-icon>mdi-download</v-icon> rechts oben in der Ecke. Damit kannst Du sie einfach auf Deinem Gerät speichern und weiterverwenden. 
              Gewisse Übersichtsgrafiken müssen erst mit <v-icon >mdi-arrow-expand</v-icon> expandiert werden, damit die Download-Funktion verfügbar ist. Die Grafiken werden in der grösse exportiert, wie sie auf dem Bildschirm angezeigt werden.
            </div>    
          </div>
          <div v-else>
            <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-2xl font-pj pb-v-md break-manual' : 'darkest text-v-4xl font-pj pb-v-xl break-manual'">
              <b>Graphics for Social Media:</b> Easy Export
            </div> 
            <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-base font-pj  pb-v-xl' : 'darkest text-v-base font-pj max-w-2xl  pb-v-xl'">
              You are welcome to use our graphics on social media in your posts or videos and include them as part of your analysis. Of course, we also appreciate a mention of Valuetion.
            </div>    
            <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-base font-pj  pb-v-xl' : 'darkest text-v-base font-pj max-w-2xl  pb-v-xl'">
              The graphics on Valuetion have a small export button <v-icon>mdi-download</v-icon> in the top right corner. This allows you to easily save and reuse them on your device. 
              Certain overview graphics must first be expanded with <v-icon >mdi-arrow-expand</v-icon> before the download function is available. The graphics are exported in the size they are displayed on the screen.
            </div>   
          </div>      
          <div v-if="(companyValuation != null && companyDetail != null && unitOfAccount != null)" class="sm:px-v-xl py-v-md">
            <CompanyValuationHistorical v-if="companyValuation && companyValuation.isValid && marketCapData && unitOfAccount" 
                v-bind:valuationData="companyValuation" 
                v-bind:marketCapData="marketCapData" 
                v-bind:marketCap="companyDetail.marketCap"
                v-bind:unitOfAccount="unitOfAccount" 
                v-bind:height="historicalValuationHeight" 
                v-bind:width="historicalValuationWidth"
                v-bind:companyName="companyDetail.companyName"/>
          </div>    
          <div v-else class="flex flex-col">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </div>
        </div>  
        <div class="button_container pt-v-3xl">
          <button type="button" class="button-left flex-none text-medium border rounded-md border-solid regular font-pj transition-all duration-200 py-v-sm px-v-md hover:text-medium-light" @click="gotoStep('financials_cashflow')">
            {{ strings['back'] }}
          </button>
          <button type="button" class="button-right flex-none bg-primary border rounded-md border-solid border-primary regular text-lightest font-pj transition-all duration-200 py-v-sm px-v-md hover:bg-primary-light hover:border-primary-light" @click="gotoStep('screener')">
            {{ strings['next'] }}
          </button>
        </div>
      </section>
    </div>


    
    <!-- step screener -->
    <div v-if="showAll || step=='screener'">
      <section :class="$vuetify.breakpoint.smAndDown ? 'py-v-xl px-v-xl' : 'py-v-3xl px-v-4xl'">
        <div class="grid grid-cols-1 gap-x-v-xl sm:grid-cols-2">
          <div v-if="language=='de'">
            <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-2xl font-pj pb-v-md break-manual' : 'darkest text-v-4xl font-pj pb-v-xl break-manual'">
              <b>Screener:</b> finde unterbewertete Aktien
            </div>
            <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-base font-pj pb-v-xl' : 'darkest text-v-base font-pj max-w-2xl  pb-v-xl'">
              Bereit, unterbewertete Aktien zu entdecken? Unser Screener macht es einfach. Filtere nach Branche, Kriterien und Bewertungen von Value Investing Legenden um Deine nächste Investition zu finden.
            </div>
            <div class="pb-v-xl text-v-base font-pj">
              <ul>
                <li class="">
                  <b>Kompetenzbereich</b><br>
                  Wähle Branche, Sektor, Standort.
                </li>
                <li class="pt-v-md">
                  <b>Definiere Qualitätskriterien für das Unternehmen</b><br>
                  Setze Mindestanforderungen für Größe, Wachstum, Kapitalrendite, Verschuldung und mehr.
                </li>
                <li class="pt-v-md">
                  <b>Bewertung vs. Preis</b><br>
                  Identifiziere unterbewertete Unternehmen und analysiere sie auf der Übersichtsseite.
                </li>
              </ul>
            </div>       
          </div>
          <div v-else>
            <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-2xl font-pj pb-v-md break-manual' : 'darkest text-v-4xl font-pj pb-v-xl break-manual'">
              <b>Screener:</b> Find Undervalued Gems
            </div>
            <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-base font-pj pb-v-xl' : 'darkest text-v-base font-pj max-w-2xl  pb-v-xl'">
              Ready to discover undervalued stocks? Our screener makes it easy. Filter by industry, criteria from value investing legends, and more to find your next investment opportunity.
            </div>
            <div class="pb-v-xl text-v-base font-pj">
              <ul>
                <li class="">
                  <b>Circle of Competence</b><br>
                  Choose industry, sector, location.
                </li>
                <li class="pt-v-md">
                  <b>Define quality criteria for the company</b><br>
                  Set minimum requirements for size, growth, return on capital, debt, and more. 
                </li>
                <li class="pt-v-md">
                  <b>Valuation vs. Price</b><br>
                  Identify undervalued companies and analyze them on the overview page.
                </li>
              </ul>
            </div>       
          </div>
          <div>
            <div><img src="../assets/screener.png" alt="image" style="height: 100%; width: 100%; max-height: 500px; object-fit: contain;" /></div>
          </div>
        </div>  
        <div class="button_container pt-v-3xl">
          <button type="button" class="button-left flex-none text-medium border rounded-md border-solid regular font-pj transition-all duration-200 py-v-sm px-v-md hover:text-medium-light" @click="gotoStep('export_graphics')">
            {{ strings['back'] }}
          </button>
          <button type="button" class="button-right flex-none bg-primary border rounded-md border-solid border-primary regular text-lightest font-pj transition-all duration-200 py-v-sm px-v-md hover:bg-primary-light hover:border-primary-light" @click="gotoStep('dashboard')">
            {{ strings['next'] }}
          </button>
        </div>
      </section>
    </div>

    <!-- step dashboard -->
    <div v-if="showAll || step=='dashboard'">
      <section :class="$vuetify.breakpoint.smAndDown ? 'py-v-xl px-v-xl' : 'py-v-3xl px-v-4xl'">
        <div class="grid grid-cols-1 gap-x-v-xl sm:grid-cols-2">
          <div v-if="language=='de'">
            <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-2xl font-pj pb-v-md break-manual' : 'darkest text-v-4xl font-pj pb-v-xl break-manual'">
              <b>Dashboard:</b> behalte Deine Watchlist im Auge
            </div>
            <div v-if="trackSteps" :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-base font-pj  pb-v-xl' : 'darkest text-v-base font-pj max-w-2xl  pb-v-xl'">
              Wir haben {{companyDetail.companyName}} ({{companyDetail.id}}) zur Watchlist in Deinem Dashboard hinzugefügt. Du wirst eine Benachrichtigung erhalten, wenn das Unternehmen neue Finanzdaten meldet.
            </div>  
            <div v-if="!trackSteps" :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-base font-pj  pb-v-xl' : 'darkest text-v-base font-pj max-w-2xl  pb-v-xl'">
              Um Unternehmen zu Deiner Watchlist hinzuzufügen, folge ihnen einfach auf ihren Übersichtsseiten.              
              Du wirst eine Benachrichtigung erhalten, wenn das Unternehmen neue Finanzdaten meldet.
            </div>  
            <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-base font-pj  pb-v-xl' : 'darkest text-v-base font-pj max-w-2xl  pb-v-xl'">
              Vielen Dank, dass Du Dir Zeit für die Tour genommen hast. Du bist nun bereit mit Valuetion loszulegen. Viel Spaß beim Investieren!
            </div>      
          </div>
          <div v-else>
            <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-2xl font-pj pb-v-md break-manual' : 'darkest text-v-4xl font-pj pb-v-xl break-manual'">
              <b>Dashboard: </b> Keep track of your watchlist
            </div>
            <div v-if="trackSteps" :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-base font-pj  pb-v-xl' : 'darkest text-v-base font-pj max-w-2xl  pb-v-xl'">
              We added {{companyDetail.companyName}} ({{companyDetail.id}}) to the watchlist in your dashboard. You will receive a notification if the company reports new financial data.
            </div>  
            <div v-if="!trackSteps" :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-base font-pj  pb-v-xl' : 'darkest text-v-base font-pj max-w-2xl  pb-v-xl'">
              To add companies to your watchlist, just follow the companies on their overview pages. You will receive a notification if the company reports new financial data.
            </div>  
            <div :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-base font-pj  pb-v-xl' : 'darkest text-v-base font-pj max-w-2xl  pb-v-xl'">
              Thanks for joining our tour! You're now ready to explore and invest with Valuetion. Happy investing!
            </div>      
          </div>
          <div>
            <div><img src="../assets/list.png" alt="image" style="height: 100%; width: 100%; max-height: 500px; object-fit: contain;" /></div>
          </div>
        </div>  
        <div class="button_container pt-v-xl">
          <button type="button" class="button-left flex-none text-medium border rounded-md border-solid regular font-pj transition-all duration-200 py-v-sm px-v-md hover:text-medium-light" @click="gotoStep('screener')">
            {{ strings['back'] }}
          </button>
          <button type="button" class="button-right flex-none bg-primary border rounded-md border-solid border-primary regular text-lightest font-pj transition-all duration-200 py-v-sm px-v-md hover:bg-primary-light hover:border-primary-light" @click="gotoStep('done')">
            {{ strings['done'] }}
          </button>
        </div>
      </section>
    </div>

  </div>
</template>


