<script>
import { sessionManager } from '@/main';
import GraphThumbnail from '@/components/graph-thumbnail.vue';

import { formatGermanDate } from '../helper';

export default {
  name: 'SiteStatistics',
  components: {
    GraphThumbnail,
  },
  data() {
    return {
      languageStrings: {
        en: {
          title: 'Site statistics',
        },
        de: {
          title: 'Site statistics',
        },
      },
      userHeaders: [
        {
          text: 'Day',
          value: 'day',
          width: '200px',
          sortable: false,
        },
        {
          text: 'Sum',
          value: 'numSum',
          width: '20px',
          sortable: false,
        },
      ],
      pageHeaders: [
        {
          text: 'Day',
          value: 'day',
          width: '200px',
          sortable: true,
        },
      ],
      items: null,
      itemsUser: null,
      language: '',
      totalNumberOfUsers: 0,
      totalNumberOfActiveUsers: 0,
      totalNumberOfEngagedUsers: 0,
      totalNumberOfActiveandEngagedUsers: 0,
      totals: {},
      lastWeekTotals: {},
      topArticles: [],
      topCommunityArticles: []
    };
  },
  created() {
    this.language = sessionManager.read('language');

    if (!sessionManager.isAdmin()) {
      this.$router.push('/login');
    }
  },
  beforeMount() {
    this.fetchStatistics();
  },
  computed: {
    strings() {
      return this.languageStrings[this.language];
    },
  },
  methods: {
    isEngagedUser(user) {
      let numberOfDaysActive = 0;
      user.dailyEntries.forEach((entry) => {
        if (entry.numSum > 0) numberOfDaysActive++;
      });
      return numberOfDaysActive >= 3;
    },
    isActiveUser(user) {
      return user.dailyEntries.some((entry) => entry.numSum > 0);
    },
    initializeHeaders(eventNames) {
      eventNames.forEach((eventName) => {
        this.userHeaders.push({
          text: eventName,
          value: `eventCounts.${eventName}`,
          width: '20px',
          sortable: false,
        });
        this.pageHeaders.push({
          text: eventName,
          value: eventName,
          width: '20px',
          sortable: false,
        });
      });
    },
    computeUserStats(userStat) {

      console.log(userStat);

      this.totalNumberOfUsers = userStat.length;
      const eventNames = new Set();

      userStat.forEach((u) => {
        u.dailyEntries.forEach((entry) => {
          Object.keys(entry.eventCounts).forEach((eventName) => eventNames.add(eventName));
        });
      });

      this.initializeHeaders(Array.from(eventNames));

      // compose the graph data
      // if a user has more than 20 daily entries, the graph has value 4, for more than 10 its 3 and for more than 5 its 2 and for more than 0 its 1
      // don't normalize the data for the first user
      var firstUser = true;
      userStat.forEach((u) => {
        u.graph = [];
        u.dailyActivitySimple.forEach((entry) => {
          let value = 0;
          if (firstUser) {
            value = entry;
          } 
          else
          {
            if(entry > 100)
            {
              value = 10;
            } else if(entry > 50) {
              value = 7;
            } else if(entry > 30) {
              value = 6;
            } else if (entry > 20) {
              value = 5;
            } else if (entry > 10) {
              value = 3;
            } else if (entry > 5) {
              value = 2;
            } else if (entry > 0) {
              value = 1;
            } else {
              value = 0;
            }
          }          
          u.graph.push(value);
        });
        firstUser = false;
        u.graph.reverse();
      });


      this.itemsUser = userStat;

      this.totalNumberOfActiveUsers = userStat.filter(this.isActiveUser).length;
      this.totalNumberOfEngagedUsers = userStat.filter(this.isEngagedUser).length;
      this.totalNumberOfActiveandEngagedUsers = userStat.filter((u) => this.isActiveUser(u) && this.isEngagedUser(u)).length;

      console.log("userStat.dailyEntries");
      console.log(userStat.dailyEntries);
    },
    computePageStats(pageStat) {
      const totals = {};
      const lastWeekTotals = {};
      const today = new Date();

      pageStat.forEach((stat, day) => {
        Object.keys(stat).forEach((key) => {
            if (key !== 'day') {
                if (!totals[key]) {
                    totals[key] = {
                        all: 0,
                        unique: 0,
                        allRegistered: 0,
                        uniqueRegistered: 0
                    };
                }

                totals[key]['all'] += stat[key].all;
                totals[key]['unique'] += stat[key].unique;
                totals[key]['allRegistered'] += stat[key].allRegistered;
                totals[key]['uniqueRegistered'] += stat[key].uniqueRegistered;

                if (day < 7) { // Consider only stats from the last 7 days
                    if (!lastWeekTotals[key]) {
                        lastWeekTotals[key] = {
                            all: 0,
                            unique: 0,
                            allRegistered: 0,
                            uniqueRegistered: 0
                        };
                    }

                    lastWeekTotals[key]['all'] += stat[key].all;
                    lastWeekTotals[key]['unique'] += stat[key].unique;
                    lastWeekTotals[key]['allRegistered'] += stat[key].allRegistered;
                    lastWeekTotals[key]['uniqueRegistered'] += stat[key].uniqueRegistered;
                }
            }
        });
      });


      this.totals = totals;
      this.lastWeekTotals = lastWeekTotals;

      this.items = pageStat.map((stat) => ({
        day: formatGermanDate(stat.day),
        ...stat,
      }));

      console.log("pageStat");
      console.log(pageStat);
    },
    async fetchStatistics() {
      const url = '/api/HttpTriggerGetUserEvents?';
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          JWT: sessionManager.read('token'),
        },
      });

      if (response.ok) {
        const statistics = await response.json();
        const pageStat = statistics.pageStat;
        const userStat = statistics.userStat;
        userStat.reverse();

        this.topArticles = statistics.articleStat;
        this.topCommunityArticles = statistics.communityArticleStat;

        console.log("topArticles");
        console.log(this.topArticles);
        console.log("topCommunityArticles");
        console.log(this.topCommunityArticles);

        this.computeUserStats(userStat);
        this.computePageStats(pageStat);
      } else if (response.status === 401) {
        this.$router.push('/login');
      }
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      return new Intl.DateTimeFormat('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }).format(date);
    },
  },
};
</script>

<style scoped>
.v-data-table {
  font-family: Plus Jakarta Sans;
  font-size: 12px !important;
  font-weight: 400;
}

.v-data-table >>> th {
  font-family: Plus Jakarta Sans;
  font-size: 12px !important;
  font-weight: normal !important;
  vertical-align: baseline !important;
  padding-left: 0 !important;
  color: #1d270f !important;
}

.v-data-table >>> td {
  font-family: Plus Jakarta Sans;
  font-size: 12px !important;
  font-weight: normal !important;
  padding-left: 0 !important;
  color: #1d270f !important;
}
</style>

<template>
  <div>
    <div class="bg-primary flex justify-center">
      <h2 :class="$vuetify.breakpoint.smAndDown ? 'text-lightest text-v-xl font-pj font-bold py-v-md' : 'text-lightest text-v-xl font-pj font-bold py-v-md'">{{ strings.title }}</h2>
    </div>

    <div :class="$vuetify.breakpoint.smAndDown ? 'px-v-2xl flex justify-center' : 'px-v-4xl flex justify-center'">
      <div class="max-w-4xl pt-v-xl">
        <h3 :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-xl font-pj font-bold py-v-md' : 'darkest text-v-xl font-pj font-bold py-v-md'">Users</h3>
        <v-simple-table dense class="py-v-md">
          <template v-slot:default>
            <tbody>
              <tr>
                <td class="darkest font-pj text-v-base pl-0">Total # users:</td>
                <td class="darkest font-pj text-v-base pl-0">100%</td>
                <td class="darkest font-pj text-v-base pl-0">{{ totalNumberOfUsers }}</td>
              </tr>
              <tr>
                <td class="darkest font-pj text-v-base pl-0"># Active users (last 30 days):</td>
                <td class="darkest font-pj text-v-base pl-0">{{ (100 * (totalNumberOfActiveUsers / totalNumberOfUsers)).toFixed(1) }}%</td>
                <td class="darkest font-pj text-v-base pl-0">{{ totalNumberOfActiveUsers }}</td>
              </tr>
              <tr>
                <td class="darkest font-pj text-v-base pl-0"># Engaged users (3 different days):</td>
                <td class="darkest font-pj text-v-base pl-0">{{ (100 * (totalNumberOfEngagedUsers / totalNumberOfUsers)).toFixed(1) }}%</td>
                <td class="darkest font-pj text-v-base pl-0">{{ totalNumberOfEngagedUsers }}</td>
              </tr>
              <tr>
                <td class="darkest font-pj text-v-base pl-0"># Active engaged users:</td>
                <td class="darkest font-pj text-v-base pl-0">{{ (100 * (totalNumberOfActiveandEngagedUsers / totalNumberOfUsers)).toFixed(1) }}%</td>
                <td class="darkest font-pj text-v-base pl-0">{{ totalNumberOfActiveandEngagedUsers }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </div>

    <div :class="$vuetify.breakpoint.smAndDown ? 'px-v-2xl' : 'px-v-4xl'">
      <div class="pt-v-xl">
        <h3 :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-xl font-pj font-bold py-v-md' : 'darkest text-v-xl font-pj font-bold py-v-md'">User statistics</h3>
        <div v-for="(userStat) in itemsUser" :key="userStat.id">
          <div class="darkest font-pj text-v-tiny pl-0 pt-v-4xl">{{ userStat.name }} {{ userStat.surname }} : {{ userStat.email }} : {{ userStat.id }} : {{ userStat.language }} : {{ userStat.numberOfActiveDays }} active days</div>
          <GraphThumbnail :data="userStat.graph" :width="1000" :height="50" />
          <v-simple-table v-if="userStat.dailyEntries != null" dense>
            <thead>
              <tr>
                <th class="darkest font-pj text-v-base pl-0">Day</th>
                <th class="darkest font-pj text-v-base pl-0">Sum</th>
                <th v-for="(value, key, j) in userStat.dailyEntries[0].eventCounts" :key="j" class="darkest font-pj text-v-base pl-0">{{ key }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(entry, i) in userStat.dailyEntries" :key="i">
                <td v-if="entry.numSum > 0" class="darkest font-pj text-v-base pl-0">{{ i }}</td>
                <td v-if="entry.numSum > 0" class="darkest font-pj text-v-base pl-0">{{ entry.numSum }}</td>
                <td v-if="entry.numSum > 0" v-for="(value, key, j) in entry.eventCounts" :key="j" class="darkest font-pj text-v-base pl-0">
                  {{ value }}
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </div>
      </div>
    </div>

    <div :class="$vuetify.breakpoint.smAndDown ? 'px-v-2xl' : 'px-v-4xl'">
      <div class="pt-v-xl">
        <h3 :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-xl font-pj font-bold py-v-md' : 'darkest text-v-xl font-pj font-bold py-v-md'">Landing page statistics</h3>
        <v-simple-table dense class="py-v-md">
          <template v-slot:default>
            <tbody>
              <tr>
                <td class="darkest font-pj text-v-base pl-0">Total Landing Page:</td>
                <td class="darkest font-pj text-v-base pl-0">100%</td>
                <td class="darkest font-pj text-v-base pl-0">{{ totals.landingPage['all'] }}</td>
              </tr>
              <tr>
                <td class="darkest font-pj text-v-base pl-0">Total Beta:</td>
                <td class="darkest font-pj text-v-base pl-0">{{ (100 * (totals.beta['all'] / totals.landingPage['all'])).toFixed(1) }}%</td>
                <td class="darkest font-pj text-v-base pl-0">{{ totals.beta['all'] }}</td>
              </tr>
              <tr>
                <td class="darkest font-pj text-v-base pl-0">Total Beta Registrations:</td>
                <td class="darkest font-pj text-v-base pl-0">{{ (100 * (totals.betaRegistered['all'] / totals.landingPage['all'])).toFixed(1) }}%</td>
                <td class="darkest font-pj text-v-base pl-0">{{ totals.betaRegistered['all'] }}</td>
              </tr>
              <tr>
                <td class="darkest font-pj text-v-base pl-0">Last Week Landing Page:</td>
                <td class="darkest font-pj text-v-base pl-0">100%</td>
                <td class="darkest font-pj text-v-base pl-0">{{ lastWeekTotals.landingPage['all'] }}</td>
              </tr>
              <tr>
                <td class="darkest font-pj text-v-base pl-0">Last Week Beta:</td>
                <td class="darkest font-pj text-v-base pl-0">{{ (100 * (lastWeekTotals.beta['all'] / lastWeekTotals.landingPage['all'])).toFixed(1) }}%</td>
                <td class="darkest font-pj text-v-base pl-0">{{ lastWeekTotals.beta['all'] }}</td>
              </tr>
              <tr>
                <td class="darkest font-pj text-v-base pl-0">Last Week Beta Registrations:</td>
                <td class="darkest font-pj text-v-base pl-0">{{ (100 * (lastWeekTotals.betaRegistered['all'] / lastWeekTotals.landingPage['all'])).toFixed(1) }}%</td>
                <td class="darkest font-pj text-v-base pl-0">{{ lastWeekTotals.betaRegistered['all'] }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <h3 :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-xl font-pj font-bold py-v-md' : 'darkest text-v-xl font-pj font-bold py-v-md'">Landing page statistics #unique users</h3>
        <v-simple-table v-if="items != null" dense>
          <thead>
            <tr>
              <th v-for="(value, key, j) in items[0]" :key="j" class="darkest font-pj text-v-base pl-0">{{ key }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(entry, i) in items" :key="i">
              <td v-for="(value, key, j) in entry" :key="j" class="darkest font-pj text-v-base pl-0">
                <span v-if="key === 'day'">{{ formatDate(value) }}</span>
                <span v-else>
                  <span v-if="value.all > 0">
                    all: {{ value.all }} | {{ value.unique }} <br>
                    reg: {{ value.allRegistered }} | {{ value.uniqueRegistered }}
                  </span>
                  <span v-else>-</span>
                </span>
              </td>
            </tr>
          </tbody>
        </v-simple-table>

        <h3 :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-xl font-pj font-bold py-v-md' : 'darkest text-v-xl font-pj font-bold py-v-md'">Top Articles</h3>
        <v-simple-table dense>
          <thead>
            <tr>
              <th class="darkest font-pj text-v-base pl-0">Title</th>
              <th class="darkest font-pj text-v-base pl-0">Source</th>
              <th class="darkest font-pj text-v-base pl-0">Users</th>
              <th class="darkest font-pj text-v-base pl-0">Total Clicks</th>
              <th class="darkest font-pj text-v-base pl-0">Link</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="article in topArticles" :key="article.id">
              <td class="darkest font-pj text-v-base pl-0">{{ article.title }}</td>
              <td class="darkest font-pj text-v-base pl-0">{{ article.source }}</td>
              <td class="darkest font-pj text-v-base pl-0">{{ Object.keys(article.clickedByUser).length }}</td>
              <td class="darkest font-pj text-v-base pl-0">{{ Object.values(article.clickedByUser).reduce((a,b) => a + b, 0) }}</td>
              <td class="darkest font-pj text-v-base pl-0"><a :href="article.url" target="_blank">Link</a></td>
            </tr>
          </tbody>
        </v-simple-table>

        <h3 :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-xl font-pj font-bold py-v-md' : 'darkest text-v-xl font-pj font-bold py-v-md'">Top Community Articles</h3>
        <v-simple-table dense>
          <thead>
            <tr>
              <th class="darkest font-pj text-v-base pl-0">Title</th>
              <th class="darkest font-pj text-v-base pl-0">Source</th>
              <th class="darkest font-pj text-v-base pl-0">Users</th>
              <th class="darkest font-pj text-v-base pl-0">Total Clicks</th>
              <th class="darkest font-pj text-v-base pl-0">Link</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="article in topCommunityArticles" :key="article.id">
              <td class="darkest font-pj text-v-base pl-0">{{ article.title }}</td>
              <td class="darkest font-pj text-v-base pl-0">{{ article.source }}</td>
              <td class="darkest font-pj text-v-base pl-0">{{ Object.keys(article.clickedByUser).length }}</td>
              <td class="darkest font-pj text-v-base pl-0">{{ Object.values(article.clickedByUser).reduce((a,b) => a + b, 0) }}</td>
              <td class="darkest font-pj text-v-base pl-0"><a :href="article.url" target="_blank">Link</a></td>
            </tr>
          </tbody>
        </v-simple-table>
      </div>
    </div>
  </div>
</template>
